:root {
	--primary-dark-900-hsl: 240 calc(1 * 11.1%) 1.8%;
	--primary-light-900-hsl: 240 calc(1 * 7.7%) 2.5%;
}

.UserLanding-sidebar {
	width: 240px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	user-select: none;
}

#dark.UserLanding-sidebar {
	background-color: #303136;
}

#light.UserLanding-sidebar {
	background-color: #f2f3f5;
}

#purple.UserLanding-sidebar {
	background-color: #7f73ba;
}

.UserLanding-sidebar-header {
	width: inherit;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	box-sizing: border-box;
	border-bottom: 1px;
	box-shadow: 0 1px 0 hsl(240 11.1% 1.8% / 0.2),
		0 1.5px 0 hsl(240 7.7% 2.5%/0.05), 0 2px 0 hsl(240 11.1% 1.8%/0.05);
}

#dark.UserLanding-sidebar-header {
	color: #ffffff;
}

#light.UserLanding-sidebar-header {
	color: #060607;
}

#purple.UserLanding-sidebar-header {
	color: #393065;
}

.UserLanding-sidebar-channel-content {
	height: calc(100% - 98px);
	overflow-y: scroll;
}

.UserLanding-sidebar-channel-category-container {
	padding-top: 16px;
	color: #96989d;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* width: 240px; */
}

#light.UserLanding-sidebar-channel-category-container {
	color: #5e6772;
}

#purple.UserLanding-sidebar-channel-category-container {
	color: #c8c7d1;
}

.UserLanding-sidebar-channel-category-name {
	width: 195px;
}

.fa-angle-down {
	font-size: 8px;
	padding: 4px;
}

.big-angle-down {
	font-size: 14px;
}

.align-right {
	justify-self: right;
	font-size: 15px;
}

#light.align-right {
	color: red;
}

.UserLanding-sidebar-channel-list {
	width: 100%;
	box-sizing: border-box;
	color: #96989d;
	display: flex;
	flex-direction: column;
	padding: 8px;
	overflow-y: auto;
}

.hash {
	font-weight: bold;
}

.UserLanding-sidebar-channel-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.UserLanding-sidebar-channel-name {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 34px;
	color: #96989d;
	cursor: pointer;
	padding: 7px;
	box-sizing: border-box;
	font-size: 16px;
}

#purple.UserLanding-sidebar-channel-name {
	color: #fff;
}

a.UserLanding-sidebar-channel-name {
	text-decoration: none;
}

.UserLanding-sidebar-channel-name.active {
	color: #ffffff;
	cursor: pointer;
	background-color: #43474e;
	text-decoration: none;
	border-radius: 4px;
}

#light.UserLanding-sidebar-channel-name.active {
	color: #303031;
	background-color: #d1d5da;
}

#purple.UserLanding-sidebar-channel-name.active {
	color: #4c4087;
	background-color: #bfb9dd;
}

.UserLanding-sidebar-channel-name:hover {
	background-color: #3c4045;
	border-radius: 4px;
}

#light.UserLanding-sidebar-channel-name:hover {
	background-color: #d1d5da;
}

#purple.UserLanding-sidebar-channel-name:hover {
	background-color: #bfb9dd3a;
}

.UserLanding-sidebar-channel-buttons {
	display: flex;
	align-items: center;
	padding-right: 20px;
	/* gap: 8px; */
}

#purple.UserLanding-sidebar-channel-buttons {
	color: #dfdcee;
}

.fa-user-plus,
.fa-gear {
	font-size: 12px;
}

.UserLanding-sidebar-channel-user-info {
	position: absolute;
	bottom: 0;
	width: 240px;
	height: 52px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.UserLanding-sidebar-yingyang {
	width: 100%;
	padding: 8px;
	background: transparent;
	border: none;
	color: #b9bbbe;
	text-align: left;
}

.UserLanding-sidebar-yingyang:hover {
	width: 100%;
	background: lightgrey;
	border: none;
	color: #2a2b2f;
	text-align: left;
	border-radius: 3px;
}

#dark.UserLanding-sidebar-channel-user-info {
	background-color: #2a2b2f;
}

#light.UserLanding-sidebar-channel-user-info {
	background-color: #ebedef;
}

#purple.UserLanding-sidebar-channel-user-info {
	background-color: #675c9f;
}

.UserLanding-sidebar-channel-user-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 122px;
	box-sizing: border-box;
	gap: 8px;
	padding-left: 8px;
}

.UserLanding-sidebar-channel-user-icons {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding-left: 2px; */
	overflow: hidden;
}

.UserLanding-sidebar-channel-user-icon {
	width: 100px;
	aspect-ratio: 1;
	border-radius: 50%;
	object-fit: cover;
}

.UserLanding-sidebar-channel-user-name {
	width: calc(100% - 40px);
	font-size: 12px;
	color: #ffffff;
}

#light.UserLanding-sidebar-channel-user-name {
	color: #060607;
}

#purple.UserLanding-sidebar-channel-user-name {
	color: #fff;
}

.UserLanding-sidebar-channel-user-actions {
	color: #96989d;
	display: flex;
	align-items: center;
	gap: 14px;
	padding-right: 14px;
}

#purple.UserLanding-sidebar-channel-user-actions {
	color: #dfdcee;
}

.user-gear {
	font-size: 16px;
	cursor: pointer;
	/* margin-left: 30px; */
}

.user-ying-yang {
	cursor: pointer;
}

.UserLanding-sidebar-channel-user-home,
.UserLanding-sidebar-channel-user-logout {
	width: 100%;
	padding: 8px;
	background: transparent;
	border: none;
	color: #b9bbbe;
	text-align: left;
}

.UserLanding-sidebar-channel-user-home:hover,
.UserLanding-sidebar-channel-user-logout:hover {
	width: 100%;
	background: lightgrey;
	border: none;
	color: #2a2b2f;
	text-align: left;
}

.UserLanding-sidebar-channel-user-home:hover {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.UserLanding-sidebar-channel-user-logout:hover {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.settings-tooltip,
.themes-tooltip {
	position: relative;
	display: inline-block;
}

.settings-tooltip .settings-tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -47px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.themes-tooltip .themes-tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -53px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.settings-tooltip .settings-tooltiptext::after,
.themes-tooltip .themes-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.settings-tooltip:hover .settings-tooltiptext,
.themes-tooltip:hover .themes-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.friends-tooltip {
	position: relative;
	display: inline-block;
}

.friends-tooltip .friends-tooltiptext {
	visibility: hidden;
	width: 60px;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -37px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.friends-tooltip .friends-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.friends-tooltip:hover .friends-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.sliding-tooltip,
.surrender-tooltip {
	position: relative;
	display: inline-block;
}

.surrender-tooltip .surrender-tooltiptext {
	visibility: hidden;
	width: 80px;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 3px 0;
	position: absolute;
	z-index: 1;
	bottom: 155%;
	left: 34px;
	margin-left: -68px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.sliding-tooltip .sliding-tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -52px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.sliding-tooltip .sliding-tooltiptext::after,
.surrender-tooltip .surrender-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.sliding-tooltip:hover .sliding-tooltiptext,
.surrender-tooltip:hover .surrender-tooltiptext {
	visibility: visible;
	opacity: 1;
}
