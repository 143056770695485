#modal, #menu-modal {
    position: fixed;
    /* margin-top: 80px; */
    /* height: calc(100vh - 80px); */
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

#menu-modal {
    margin-top: 0;
    height: 100%;
    padding-right: 0;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#menu-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

#modal-content {
    border-radius: 4px;
    position: absolute;
    /* background-color: #35393e; */
    overflow-y: auto;
    max-height: 90%;
    height: max-content;
    /* padding-bottom: 24px; */
    display: flex;
    justify-content: center;
    z-index: 99999;
    /* border-radius: 12px; */
}

#modal-content {
    /* background-color: #ffffff; */
    background-color: none;
}


#menu-modal-content {
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    /* right: -15px; */
    right: 0;
    top: 0;
    width: 330px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 999999;
}
