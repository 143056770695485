.delete-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  width: 420px;
  background-color: #36393f;
}

#light.delete-container {
  background-color: white;
}

.delete-header {
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 20px;
  margin-bottom: 5px;
}

#light.delete-header {
  color: black;
}

.delete-subtext {
  background-color: #faa81a;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 20px 20px;
}

.delete-subtext > span {
  font-weight: bold;
}

.delete-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: #2f3136;
  width: 100%;
  padding: 18px 0px;
}

#light.delete-buttons {
  background-color: #f3f4f5;
}

.delete-delete-button {
  margin-right: 20px;
  margin-left: 10px;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  color: white;
  background-color: #d83c3e;
  border: none;
}

.delete-delete-button:hover {
  background-color: #da2a2c;
}

.delete-cancel-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 13px;
}

#light.delete-cancel-button {
  color: #a5a7af;
}

#light.delete-cancel-button:hover {
  color: #8e9096;
}
