@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700;900&family=Roboto:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700;900&family=Roboto:wght@300;400&display=swap");

.Form-wrapper {
  width: 100%;
  height: 100vh;
  font-family: Roboto;
  /* padding: 0px 16px; */
}

.Form-container,
.Form-container-signup {
  width: 100%;
  height: 100%;
  background-color: #36393f;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  box-sizing: border-box;
}

.Form-logo {
  height: 34px;
  margin-bottom: 16px;
}

.Form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Form-title {
  margin-bottom: 8px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  /* font-family: Roboto; */
}

.LoginForm-subtext {
  font-size: 15px;
  color: #b9bbbe;
  font-family: Roboto;
  font-weight: 400;
}

.Form-form-container {
  width: calc(100% - 32px);
}

.Form-form {
  width: 100%;
  margin-top: 20px;
}

.Form-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Form-form-group > label {
  color: #b9bbbe;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.Form-form-group > input {
  height: 20px;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  background-color: #1f2225;
}

.Form-error-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form-error,
.Form-required {
  font-size: 12px;
  color: #ed4245;
}

.Form-required {
  font-family: Roboto;
  vertical-align: top;
  margin-left: 4px;
}

.Form-button-container {
  width: 100%;
}

.Form-submit-button {
  width: 100%;
  height: 44px;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto;
  background-color: #5865f2;
  border: none;
  border-radius: 3px;
  padding: 2px 16px;
  margin: 20px 0px 8px 0px;
}

.Form-small-text {
  color: #b9bbbe;
  font-size: 12px;
}

.Form-link {
  margin-left: 4px;
  color: #00aff4;
  cursor: pointer;
}

.Form-close-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.close-icon {
  font-size: 12px;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon:hover {
  text-decoration: underline;
  /* padding-bottom: 1px; */
  /* border-bottom: 1px solid #ffffff;
    box-sizing: border-box; */
}

.Form-link:hover {
  text-decoration: underline;
}

.Form-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Form-demos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Form-demos-header {
  font-size: 20px;
  margin-bottom: 14px;
  margin-top: 24px;
  font-weight: 500;
}

.Forms-demos-card {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  background-color: #2e3136;
  border-radius: 3px;
  padding: 12px 16px;
  margin-bottom: 12px;
}

.Form-demos-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Form-demos-image > img {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.Form-demos-username {
  width: calc(100% - 170px);
  text-align: left;
  font-size: 16px;
}

.Form-demos-button {
  width: 96px;
  background-color: #4f545c;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
}

.Form-demos-button:hover {
  background-color: #696d72;
}

.Form-group-profile-pic {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.Form-default-profile-image-container {
  border-radius: 50%;
  background-color: #5865f2;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form-default-profile-image {
    border-radius: 50%;
    /* width: 100px; */
    /* aspect-ratio: 1; */
    object-fit: cover;
}

.SignUpForm-profile-pic-upload {
    margin-top: 8px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    color: #c4c4c4;
    font-size: 13px;
}

.Form-group-profile-pic > input[type="file"] {
    display: none;
}

.clickable {
  cursor: pointer;
}

@media (min-width: 468px) {
  .Form-logo {
    display: none;
  }

  .Form-wrapper {
    /* background-color: #5865f2; */
    background-image: url("https://keenlychung.com/images/datcord_bg.svg");
    background-size: cover;
    /* background-repeat: no-repeat; */
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .Form-container,
  .Form-container-signup {
    padding: 32px;
    width: 100%;
    max-width: 480px;
    height: max-content;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  .Form-demos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Form-demos-header {
    margin-top: 26px;
  }
}

@media (min-width: 832px) {
  .Form-container {
    padding: 32px;
    width: 100%;
    max-width: 784px;
    height: max-content;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
  }

  .Form-demos-header {
    margin-bottom: 26px;
    margin-top: 0;
  }
}
