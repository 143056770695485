@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700;900&family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700;900&family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;900&family=Roboto:wght@300;400&display=swap);
#modal, #menu-modal {
    position: fixed;
    /* margin-top: 80px; */
    /* height: calc(100vh - 80px); */
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

#menu-modal {
    margin-top: 0;
    height: 100%;
    padding-right: 0;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#menu-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

#modal-content {
    border-radius: 4px;
    position: absolute;
    /* background-color: #35393e; */
    overflow-y: auto;
    max-height: 90%;
    height: -webkit-max-content;
    height: max-content;
    /* padding-bottom: 24px; */
    display: flex;
    justify-content: center;
    z-index: 99999;
    /* border-radius: 12px; */
}

#modal-content {
    /* background-color: #ffffff; */
    background-color: none;
}


#menu-modal-content {
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    /* right: -15px; */
    right: 0;
    top: 0;
    width: 330px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 999999;
}

.splash-container-top {
  height: 548px;
  height: 100%;
  max-height: 661px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.splash-top-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 999;
  max-height: 80%;
  margin-top: 60px;
  max-width: 1000px;
  padding: 56px 24px;
}

.splash-top-details-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.splash-header {
  color: white;
  font-size: clamp(24px, 5vw, 44px);
  text-transform: uppercase;
  margin-bottom: 20px;
}

.splash-top-details-subtext {
  text-align: left;
  color: white;
  width: 100%;
  line-height: 30px;
}

.splash-top-details-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The popup form - hidden by default */
.form-popup {
  display: none;
}

.open-discord-button {
  font-size: 20px;
  color: white;
  background-color: #23272a;
  border: none;
  border-radius: 28px;
  padding: 16px 32px;
  margin-top: 24px;
  transition: 0.3s;
}

.open-discord-button:hover {
  cursor: pointer;
  background-color: hsl(220, 7.7%, 22.9%);
  box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
}

.form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 28px;
  padding: 12px 28px;
  margin-top: 24px;
}

.form-input {
  margin-right: 20px;
}

.form-input > input {
  border: none;
}

.form-input > input:focus {
  outline: none;
}

.form-button > button {
  border: none;
  border-radius: 28px;
  background-color: #5865f2;
  padding: 7px 18px;
  color: white;
}

.background-image-container-parent {
  display: none;
}

.background-image-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 80;
  height: 548px;
  max-height: 658px;
  width: 100%;
  overflow: hidden;
  z-index: 20;
  /* border: 5px solid black; */
}

.splash-container-top > .background-image-container {
  top: 80px;
  height: 100%;
  width: 100%;
}

.bg-image {
  display: none;
}

.bg-image-left {
  position: absolute;
  z-index: 100;
  bottom: 0;
  margin-left: -80px;
  width: 689px;
}

.bg-image-right {
  display: none;
}

@media (min-width: 768px) {
  .splash-container-top {
    position: relative;
    overflow: hidden;
    height: 546px;
    height: 100%;
    width: 100%;
  }

  .splash-top-details-container {
    display: flex;
    flex-direction: column;
    z-index: 999;
    height: 70%;
    max-height: 80%;
    padding: 56px 24px;
  }

  .splash-top-details-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    z-index: inherit;
  }

  .splash-header {
    color: white;
    font-size: 56px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .splash-top-details-subtext {
    text-align: left;
    color: white;
    width: 60%;
    /* margin-bottom: 20px; */
    margin-bottom: 0;
    line-height: 30px;
    margin-top: 24px;
  }

  .background-image-container-parent {
    display: block;
    position: absolute;
    bottom: 0;
    width: 2560px;
    height: 100%;
  }

  .splash-top-details-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    z-index: inherit;
  }

  .splash-header {
    color: white;
    font-size: clamp(24px, 5vw, 44px);
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .background-image-container {
    position: absolute;
  }

  .splash-top-details-subtext {
    text-align: left;
    color: white;
    width: 60%;
    /* margin-bottom: 20px; */
    margin-bottom: 0;
    line-height: 30px;
    margin-top: 24px;
  }

  .background-image-container-parent {
    display: block;
    position: absolute;
    bottom: 0;
    width: 2560px;
    height: 100%;
  }
  .bg-image {
    bottom: 0;
    position: absolute;
    top: auto;
    left: -10%;
    display: block;
    z-index: 1;
  }

  .background-image-container-parent > img {
    margin-left: -1280px;
    left: 50%;
  }

  .background-image-container {
    position: absolute;
  }

  .splash-container-top > .background-image-container {
    top: 0;
    height: 100%;
  }

  .bg-image {
    bottom: 0;
    position: absolute;
    top: auto;
    left: -10%;
    display: block;
    z-index: 1;
  }

  .bg-image-left {
    display: none;
  }

  .bg-image-right {
    display: block;
    position: absolute;
    width: 615px;
    z-index: 100;
    left: 50%;
    bottom: 0;
    margin-left: -70px;
  }
}

@media (min-width: 1024px) {
  .splash-top-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1999;
    max-height: 80%;
  }

  .splash-header {
    margin: 0;
  }

  .splash-top-details-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    z-index: inherit;
  }

  .splash-top-details-subtext {
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 20px;
    line-height: 30px;
    margin-top: 24px;
  }

  .bg-image-left {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -1030px;
    display: block;
    width: auto;
    max-width: 689px;
    height: auto;
    z-index: 1;
  }

  .splash-header {
    margin: 0;
  }

  .splash-top-details-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    z-index: inherit;
  }

  .splash-top-details-subtext {
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 20px;
    line-height: 30px;
    margin-top: 24px;
  }

  .bg-image-left {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -1030px;
    display: block;
    width: auto;
    max-width: 689px;
    height: auto;
    z-index: 1;
  }

  .bg-image-right {
    position: absolute;
    max-height: 360px;
    display: block;
    z-index: 200;
    left: 50%;
    margin-left: 370px;
  }
}

.OpenModalButton-button {
  background: none;
  border: 0;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.Create-Channel {
  background: none;
  border: 0;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

#dark.OpenModalButton-button > div > .align-right {
  color: #96989d;
}

#light.fa-solid.fa-plus.align-right {
  color: #5e6772;
}

#purple.fa-solid.fa-plus.align-right {
  color: #393065;
}

.Edit,
.Delete {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  padding: 0px 5px;
  align-items: center;
  margin-left: 0;
}

.Edit-Channel {
  font-size: 12px;
  color: #96989d;
  display: flex;
  align-items: center;
  margin-left: 0;
}

#purple.Edit-Channel {
  color: #dfdcee;
}

.DeleteChannel {
  color: #ffffff;
  background: none;
  border: 0;
  font-size: 13px;
  margin-left: 8px;
}

.NavigationSplash-container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #404eed;
  width: 100%;
  height: 738px;
  /* position: relative; */
  /* overflow: hidden; */
}

.Navigation-container-wrapper {
  width: 100%;
  height: 80px;
  background-color: #404eed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navigation-container {
  height: 80px;
  background-color: #404eed;
  /* z-index: 100000; */
  width: calc(100% - 48px);
  max-width: 1260px;
  padding: 0px 24px;
}

#NotFound.Navigation-container {
  background-color: #ffffff;
}

.Navigation-list {
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
}


.Navigation-links-main {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#NotFound.Navigation-links-main > li {
  color: #060607;
}

.Navigation-logo-container {
  height: 34px;
}

.Menu-logo {
  fill: black;
}

.Navigation-logo,
.Menu-logo {
  height: 34px;
}

.Navigation-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Navigation-hamburger {
  color: #ffffff;
  font-size: 25px;
  background: none;
  border: none;
}

  #NotFound.Navigation-hamburger {
    color: #060607;
  }

.Navigation-login {
  height: 38px;
  background-color: #ffffff;
  border: 0;
  border-radius: 40px;
  padding: 7px 16px;
  font-size: 14px;
  margin-right: 16px;
}

#NotFound.Navigation-login {
  background-color: #404eed;
  color: #ffffff;
}

.Navigation-login:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px rgb(0 0 0 / 20%);
}

.MenuModal-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px 0px;
}

.MenuModal-close {
  background: none;
  border: none;
}

.fa-xmark {
  font-size: 22px;
}

.MenuModal-main-container {
  padding: 24px 48px 120px 18px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.MenuModal-divider {
  background-color: #ebedef;
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
}

.MenuModal-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
}

nav > li {
  padding: 0;
}

ul {
  padding: 0;
}

.MenuModal-nav-link {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: #23272a;
  list-style-type: none;
  padding: 8px 16px;
  box-sizing: border-box;
  text-decoration: none;
}

.MenuModal-nav-link.active {
  color: #00b0f4;
  background-color: #f6f6f6;
  border-radius: 8px;
  text-decoration: none;
}

.MenuModal-nav-link:hover {
  text-decoration: underline;
}

a {
  color: white;
  text-decoration: none;
}

nav > ul {
  padding: 0;
}

.Navigation-demo {
  margin-left: 40px;
  width: 100px;
}

.Navigation-demo-hover {
  display: none;
  /* font-size: 12px; */
}

.Navigation-demo:hover {
  cursor: pointer;
}

.Navigation-demo:hover > .Navigation-demo-no-hover {
  display: none;
}

.Navigation-demo:hover > .Navigation-demo-hover {
  display: block;
}

.MenuModal-link {
  color: #23272a;
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid red;
  background-color: yellow;
}

.MenuModal-link:hover {
  border: 5px solid red;
  background-color: yellow;
}

button {
  cursor: pointer;
}

@media (min-width: 768px) {
  .NavigationSplash-container {
    display: flex;
    height: 626px;
  }

  .Navigation-list {
    list-style-type: none;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .Navigation-links-main {
    /* width: 50%; */
    display: flex;
    grid-gap: 18px;
    gap: 18px;
    /* justify-content: space-between; */
  }

  .Navigation-hamburger {
    justify-content: center;
    display: none;
  }

  .Navigation-list > li {
    color: #ffffff;
  }
}

.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(5vh);
          transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out, -webkit-transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.splash-body-parent {
  width: 100%;
  display: flex;
  align-items: center;
}

.splash-body-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 120px 0px;
  width: 100%;
  height: 100%;
}

.splash-body-img-container {
  margin-right: 60px;
}

.body-img {
  width: 678px;
}

.splash-body-description {
  display: flex;
  flex-direction: column;
  height: 380px;
  width: 440px;
}

.splash-body-description > h2 {
  font-size: 41px;
  font-weight: 1000;
  color: #23272a;
}

.splash-body-description-text {
  margin-top: 24px;
}

.splash-body-2-parent {
  background-color: #f6f6f6;
  width: 100%;
}

.splash-body-2-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 120px 0px;
}

.splash-body-2-description > h2 {
  font-size: 56px;
  color: #23272a;
  font-weight: 1000;
  text-transform: uppercase;
}

.splash-body-2-developers-container {
  display: flex;
  flex-direction: row;
}

.developer-card {
  margin: 20px 20px;
  padding: 20px 0px;
  border: 1px solid rgb(219, 219, 219);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.developer-card:hover {
  border: 1px solid #b6b6b6;
  -webkit-filter: saturate(200%);
          filter: saturate(200%);
}

.developer-card-name {
  font-size: 16px;
  font-weight: 1000;
}

.developer-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.developer-card-info > span > a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  text-decoration: none;
  color: rgb(46, 46, 46);
  transition: 0.3s;
}

.developer-card-info > span > a:hover {
  color: #5865f2;
  text-decoration: underline;
}

.splash-body-footer-parent {
  background-color: #23272a;
  width: 100%;
  height: 100%;
}

.splash-body-footer-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-around; */
  justify-content: center;
  padding: 120px 0px;
}

.splash-body-footer-left {
  line-height: 35px;
  width: 304px;
}

.splash-body-footer-left > h1 {
  color: #5865f2;
  font-size: 41px;
  font-weight: 1000;
  text-transform: uppercase;
}

.splash-body-footer-techstack {
  line-height: 28px;
}

.splash-body-footer-techstack > h2 {
  color: #5865f2;
  font-size: 16px;
}

.splash-body-footer-techstack > h3,
li {
  color: white;
  font-size: 16px;
}

.NotFound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    grid-gap: 28px;
    gap: 28px;
}

.NotFound-milk {
    width: 300px;
}

.NotFound-header {
    color: #5865f2;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Noto Sans", sans-serif;
    font-weight: 900;
    margin-bottom: 28px;
}

.NotFound-container-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#NotFound.open-discord-button {
    width: 400px;
    align-self: center;
}

@media (min-width: 1024px) {
    .NotFound-container {
        flex-direction: row-reverse;
    }

    .NotFound-milk {
        width: 350px;
    }

    .NotFound-container-right {
        width: 350px;
        padding: 0px 100px;
    }

    .NotFound-container-left {
        width: calc(100% - 450px);
    }

}

.Form-wrapper {
  width: 100%;
  height: 100vh;
  font-family: Roboto;
  /* padding: 0px 16px; */
}

.Form-container,
.Form-container-signup {
  width: 100%;
  height: 100%;
  background-color: #36393f;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  box-sizing: border-box;
}

.Form-logo {
  height: 34px;
  margin-bottom: 16px;
}

.Form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Form-title {
  margin-bottom: 8px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  /* font-family: Roboto; */
}

.LoginForm-subtext {
  font-size: 15px;
  color: #b9bbbe;
  font-family: Roboto;
  font-weight: 400;
}

.Form-form-container {
  width: calc(100% - 32px);
}

.Form-form {
  width: 100%;
  margin-top: 20px;
}

.Form-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Form-form-group > label {
  color: #b9bbbe;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.Form-form-group > input {
  height: 20px;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  background-color: #1f2225;
}

.Form-error-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form-error,
.Form-required {
  font-size: 12px;
  color: #ed4245;
}

.Form-required {
  font-family: Roboto;
  vertical-align: top;
  margin-left: 4px;
}

.Form-button-container {
  width: 100%;
}

.Form-submit-button {
  width: 100%;
  height: 44px;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto;
  background-color: #5865f2;
  border: none;
  border-radius: 3px;
  padding: 2px 16px;
  margin: 20px 0px 8px 0px;
}

.Form-small-text {
  color: #b9bbbe;
  font-size: 12px;
}

.Form-link {
  margin-left: 4px;
  color: #00aff4;
  cursor: pointer;
}

.Form-close-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.close-icon {
  font-size: 12px;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon:hover {
  text-decoration: underline;
  /* padding-bottom: 1px; */
  /* border-bottom: 1px solid #ffffff;
    box-sizing: border-box; */
}

.Form-link:hover {
  text-decoration: underline;
}

.Form-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Form-demos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Form-demos-header {
  font-size: 20px;
  margin-bottom: 14px;
  margin-top: 24px;
  font-weight: 500;
}

.Forms-demos-card {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #2e3136;
  border-radius: 3px;
  padding: 12px 16px;
  margin-bottom: 12px;
}

.Form-demos-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Form-demos-image > img {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.Form-demos-username {
  width: calc(100% - 170px);
  text-align: left;
  font-size: 16px;
}

.Form-demos-button {
  width: 96px;
  background-color: #4f545c;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
}

.Form-demos-button:hover {
  background-color: #696d72;
}

.Form-group-profile-pic {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.Form-default-profile-image-container {
  border-radius: 50%;
  background-color: #5865f2;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form-default-profile-image {
    border-radius: 50%;
    /* width: 100px; */
    /* aspect-ratio: 1; */
    object-fit: cover;
}

.SignUpForm-profile-pic-upload {
    margin-top: 8px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    color: #c4c4c4;
    font-size: 13px;
}

.Form-group-profile-pic > input[type="file"] {
    display: none;
}

.clickable {
  cursor: pointer;
}

@media (min-width: 468px) {
  .Form-logo {
    display: none;
  }

  .Form-wrapper {
    /* background-color: #5865f2; */
    background-image: url("https://keenlychung.com/images/datcord_bg.svg");
    background-size: cover;
    /* background-repeat: no-repeat; */
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .Form-container,
  .Form-container-signup {
    padding: 32px;
    width: 100%;
    max-width: 480px;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  .Form-demos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Form-demos-header {
    margin-top: 26px;
  }
}

@media (min-width: 832px) {
  .Form-container {
    padding: 32px;
    width: 100%;
    max-width: 784px;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
  }

  .Form-demos-header {
    margin-bottom: 26px;
    margin-top: 0;
  }
}

.ServerNav-container {
	/* position: fixed; */
	/* left: 0; */
	/* top: 0; */
	width: 72px;
	height: 100vh;
	/* border: 5px solid red; */
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	padding: 12px 8px;
	box-sizing: border-box;
	overflow: hidden auto;
	flex-shrink: 0;
	-webkit-user-select: none;
	        user-select: none;
}

#dark.ServerNav-container {
	background-color: #1f2225;
}

#light.ServerNav-container {
	background-color: #e3e5e8;
}

#purple.ServerNav-container {
	background-color: #4c4087;
}

.ServerNav-divider {
	height: 2px;
	width: 32px;
	border-radius: 1px;
	margin-bottom: 8px;
}

#dark.ServerNav-divider {
	background-color: #36393f;
}

#light.ServerNav-divider {
	background-color: #c5cacf;
}

#purple.ServerNav-divider {
	background-color: #bfb9dd;
}

.ServerNav-icons,
.ServerNav-profile-gold,
.ServerNav-profile {
	color: #3ba55d;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background-color: #34363e;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin-bottom: 8px;
	transition: all 0.3s ease-in-out;
	flex-shrink: 0;
}

#purple.ServerNav-icons,
#purple.ServerNav-profile {
	color: #7f73ba;
}

.ServerNav-profile {
	background-color: #5765f2;
}

#purple.ServerNav-profile {
	background-color: #dfdcee;
}

#light.ServerNav-icons {
	background-color: white;
}

#purple.ServerNav-icons {
	background-color: #dfdcee;
}

.ServerNav-server-icons {
	/* color: #3ba55d; */
	width: 50px;
	height: 50px;
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin-bottom: 8px;
	flex-shrink: 0;
}

.ServerNav-icons::before,
.ServerNav-profile::before,
.ServerNav-profile-gold::before,
.ServerNav-server-icons::before {
	position: absolute;
	background-color: white;
	display: block;
	width: 10px;
	height: 40px;
	left: -20px;
	content: " ";
	border-radius: 5px;
	transition: 0.4s;
}

#light.ServerNav-icons::before,
#light.ServerNav-profile::before,
#light.ServerNav-server-icons::before {
	background-color: black;
}

.ServerNav-icons:active,
.ServerNav-profile:active,
.ServerNav-server-icons:active {
	background-color: #3ba55d;
}
.ServerNav-profile-gold:active {
	background-color: black;
}

#purple.ServerNav-icons:active,
#purple.ServerNav-profile:active,
#purple.ServerNav-server-icons:active {
	background-color: #f3dce8;
}

.ServerNav-icons.active::before,
.ServerNav-icons-gold.active::before,
.ServerNav-profile.active::before,
.ServerNav-server-icons.active::before {
	position: absolute;
	background-color: white;
	display: block;
	width: 10px;
	height: 35px;
	left: -6px;
	content: " ";
}

#light.ServerNav-icons.active::before,
#light.ServerNav-profile.active::before,
#light.ServerNav-server-icons.active::before {
	background-color: black;
}

#purple.ServerNav-icons.active::before,
#purple.ServerNav-profile.active::before,
#purple.ServerNav-server-icons.active::before {
	background-color: black;
}

.ServerNav-server-icons:hover.active::before {
	position: absolute;
	background-color: white;
	display: block;
	width: 10px;
	height: 35px;
	left: -6px;
	content: " ";
}

#light.ServerNav-server-icons:hover.active::before {
	background-color: black;
}

#purple.ServerNav-server-icons:hover.active::before {
	background-color: black;
}

.ServerNav-icons:hover,
.ServerNav-profile:hover {
	cursor: pointer;
	border-radius: 16px;
	color: white;
	background-color: #3ba55d;
}

.ServerNav-profile-gold:hover {
	cursor: pointer;
	border-radius: 16px;
	color: white;
	background-color: goldenrod;
}

#light.ServerNav-icons:hover {
	background-color: #3ba55d;
	color: white;
}

.ServerNav-icons:hover::before,
.ServerNav-profile:hover::before,
.ServerNav-profile-gold:hover::before,
.ServerNav-server-icons:hover::before {
	position: absolute;
	background-color: white;
	display: block;
	width: 10px;
	height: 25px;
	left: -6px;
	content: " ";
	/* border-radius: 5px; */
}

#light.ServerNav-icons:hover::before,
#light.ServerNav-profile:hover::before,
#light.ServerNav-profile-gold:hover::before,
#light.ServerNav-server-icons:hover::before {
	background-color: black;
}

.ServerNav-icon {
	width: 100%;
	/* aspect-ratio: 1; */
	height: 50px;
	width: 50px;
	object-fit: cover;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
}

.ServerNav-profile-cow-icon {
	object-fit: cover;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
}

.ServerNav-icon:hover {
	border-radius: 16px;
}

.cms-container {
	width: calc(100% - 240px);
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.cms-ct {
	height: calc(100% - 48px);
	width: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
}

.cm-form-container {
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 5px;
}

.cm-text-input {
	width: calc(100% - 45px);
	/* max-width: 90%; */
	border: 0;
	border-radius: 5px;
	text-decoration: none;
	padding: 10px;
}

#dark.cm-text-input {
	color: rgb(211, 212, 214);
	background-color: rgb(64, 68, 75);
}

#light.cm-text-input {
	color: #060607;
	background-color: #eeeef1;
}

#purple.cm-text-input {
	color: #e24f99;
	background-color: #dfdceeab;
}

/* .cms-ch-name {
	color: rgb(211, 212, 214);
	padding-bottom: 12px;
	margin-bottom: 5px;
	border-bottom: 1px solid #96989d5f;
} */

.cms-ch-name {
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	box-sizing: border-box;
	border-bottom: 1px;
	box-shadow: 0 1px 0 hsl(240 11.1% 1.8% / 0.2),
		0 1.5px 0 hsl(240 7.7% 2.5%/0.05), 0 2px 0 hsl(240 11.1% 1.8%/0.05);
}

#dark.cms-ch-name {
	color: #ffffff;
}

#light.cms-ch-name {
	color: #060607;
}

#purple.cms-ch-name {
	color: #393065;
}

.submit-cm {
	width: 100%;
	/* position: absolute; */
	bottom: 0;
	padding-right: 0;
	margin-right: 0;
	/* padding-left: 10px; */
	/* margin-left: 10px; */
	/* padding-bottom: 25px; */
	display: flex;
	justify-content: center;
	outline: 0;
	box-sizing: border-box;
}

.cm-overflow {
	/* position: relative; */
	z-index: 0;
	box-sizing: border-box;
	font-size: 16px;
	font-size: 1rem;
	white-space: break-spaces;
	line-height: 1.375rem;
	word-wrap: break-word;
	overflow-y: scroll;
	height: 100%;
	width: 100%;
}

#dark.cm-overflow {
	color: rgb(211, 212, 214);
}

#light.cm-overflow {
	color: #313338;
}

#purple.cm-overflow {
	color: #c7518c;
}

.msg-ct {
	display: flex;
	flex-direction: column;
	padding: 5px 20px;
	border-radius: 6px;
	margin: 10px 0px;
	margin-right: 5px;
	width: 100%;
}

.cms-msg-header {
	display: flex;
	flex-direction: row;
}

.cms-msg-date {
	font-size: 0.8rem;
	margin-left: 10px;
}

#dark.cms-msg-date {
	color: #96989d;
}

#light.cms-msg-date {
	color: #7d7f85;
}

#purple.cms-msg-date {
	color: #4e0052d7;
}

.cms-options {
	margin-left: auto;
	display: flex;
	flex-direction: row;
	font-size: 0.8rem;
	margin-left: auto;
	display: none;
}

#dark.cms-options {
	color: #96989d;
}

#light.cms-options {
	color: #7d7f85;
}

#purple.cms-options {
	color: #9e4ea8;
}

#dark.justify:hover {
	background-color: rgba(43, 45, 49, 0.781);
	border: 0;
}

#light.justify:hover {
	background-color: #f8f8f8;
	border: 0;
}

#purple.justify:hover {
	background-color: #9f96cb2f;
	border: 0;
}

.msg-ct:hover > .cms-msg-header > .cms-options {
	display: flex;
}

.cms-delete {
	margin-left: 25px;
}
.cms-edit {
	height: 100%;
	width: 100%;
}

.cms-edit:hover,
.cms-delete:hover {
	cursor: pointer;
	text-decoration: underline;
}

.row {
	display: flex;
	flex-direction: row;
	/* width: 100%; */
}

.edited {
	color: rgb(172, 163, 150);
	font-size: 0.7rem;
	margin-left: 10px;
}

.edit {
	padding-bottom: 10px;
	padding-top: 5px;
}

.icon {
	height: 10px;
	width: 10px;
	object-fit: cover;
	margin: 5px 1px 0px 5px;
}

.cms-member {
	color: rgb(196, 122, 228);
}

.cms-admin {
	color: rgb(103, 223, 147);
}

.cms-pending {
	color: white;
}

#light.cms-member,
#light.dms-member {
	color: #9b59b6;
}

#light.cms-admin,
#light.dms-admin {
	color: rgb(73, 193, 147);
}

#light.cms-pending {
	color: #5c5e66;
}

#purple.cms-member,
#purple.dms-member {
	color: #6b5ac0;
}

#purple.cms-admin,
#purple.dms-admin {
	color: rgb(42, 129, 95);
}

#purple.cms-pending {
	color: #5c5e66;
}

.pic-icon,
.img-link {
	width: 40px;
	height: 40px;
	aspect-ratio: 1;
	object-fit: cover;
	border-radius: 9999px;
}

.justify {
	align-items: center;
	padding: 0px 20px;
}

.cm-img-input {
	width: 30px;
	height: 30px;
	/* margin-bottom: 22px; */
	text-decoration: none;
	border: 0;
	border-radius: 50%;
	font-size: 20px;
}

#dark.cm-img-input {
	background-color: #b9bbbe;
	color: #41444a;
}

#light.cm-img-input {
	background-color: #4e5057;
	color: #eeeef1;
}

#purple.cm-img-input,
#purple.cm-img-input-x {
	background-color: #7f73ba;
	color: #dfdcee;
}

.cm-img-input-x {
	width: 30px;
	height: 30px;
	/* margin-bottom: 22px; */
	text-decoration: none;
	border: 0;
	border-radius: 50%;
	font-size: 15px;
	padding-bottom: 3px;
}

#dark.cm-img-input-x {
	background-color: #b9bbbe;
	color: #41444a;
}

#light.cm-img-input-x {
	background-color: #4e5057;
	color: #eeeef1;
}

#dark.cm-img-input:hover,
#dark.cm-img-input-x:hover {
	background-color: #dcddde;
}

#light.cm-img-input:hover,
#light.cm-img-input-x:hover {
	background-color: #313338;
}

#purple.cm-img-input:hover,
#purple.cm-img-input-x:hover {
	background-color: #c2528b;
	color: #fff;
}

.image-input {
	background-color: rgb(74, 79, 87);
}

.aws-image {
	width: auto;
	height: auto;
	max-height: 200px;
	object-fit: contain;
	align-self: flex-start;
	margin-top: 5px;
}

.cm-spacer {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	padding-left: 40px;
}

.cm-spacer2 {
	margin-top: 0px;
	padding-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}

.absolute-op {
	position: relative;
}

.loading-cms {
	width: calc(100% - 240px);
}

.cms-err {
	color: rgba(255, 91, 91, 0.9);
	position: absolute;
	bottom: 60px;
	background-color: rgba(40, 43, 42, 0.644);
	padding: 2px 8px;
	border-radius: 10px;
	font-size: 12px;
}

.cms-err-edit {
	color: rgba(255, 91, 91, 0.9);
	top: 0px;
	padding: 2px 8px 0px 8px;
	border-radius: 10px;
	font-size: 15px;
}

.empty-ct {
	margin-left: 20px;
	margin-top: 30px;
}

.empty-cms-header {
	font-size: 35px;
}

.empty-cms-body {
	margin-top: 15px;
	margin-left: 10px;
	font-size: 18px;
}

.empty-tag {
	margin-bottom: 20px;
	font-size: 50px;
	background-color: rgba(128, 128, 128, 0.123);
	width: 50px;
	height: 50px;
	padding-top: 20px;
	padding-left: 16px;
	border-radius: 50%;
}

.mootroGold {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}

.mootro-ct {
	border: 1px solid rgba(0, 0, 0, 0.592);
	border-radius: 20px;
	padding: 5px;
	background-color: rgb(218, 165, 32, 0.9);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}
.mootro-ct:hover {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
	background-color: rgb(228, 173, 35);
}

#purple.edited{
	color: #a0356a99;
}

.mootro-member-card {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	height: 450px;
	width: 350px;
}

.mootro-card {
	background-color: black;
}

#dark.mootro-member-card {
	background: #292b2f;
}

#light.mootro-member-card {
	background: #e9eaed;
}

#purple.mootro-member-card {
	background-color: black;
}

#mootro-card-header {
	margin: 0;
	border-radius: 10px 10px 0 0;
	height: 65px;
	width: 100%;
	background-color: goldenrod;
}

.theme-img {
	height: 65px;
	border-radius: 10px;
}

.mootro-h5 {
	padding-top: 5px;
}

.mootro-member-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.mootro-button {
	height: 40px;
	border: none;
	background-color: goldenrod;
	border: 3px solid black;
	border-radius: 5px;
}
.mootro-button:hover {
	background-color: rgb(248, 189, 39);
}

.card-img {
	width: 90px;
	height: 90px;
	/* border: 5px solid #292b2f; */
	border-radius: 50%;
	object-fit: cover;
}

#dark.card-img {
	border: 5px solid #292b2f;
}

#light.card-img {
	border: 5px solid #e9eaed;
}

#purple.card-img {
	border: 5px solid #dfdcee;
}

.mootro-card-content {
	border-radius: 50%;
	margin: -45px 20px;
	width: 80%;
	height: 100px;
}

.mootro-info {
	display: flex;
	flex-direction: column;
	height: 300px;
	width: 300px;
	border-radius: 10px;
	/* font-family: "Segoe UI Variable"; */
	font-family: "Roboto";
	box-sizing: border-box;
	padding: 12px;
	background-color: goldenrod;
	z-index: 10;
}

.mootro-features {
	color: black;
	font-size: 25px;
	border-bottom: 1px solid black;
}

.include.mootro-h4 {
	color: black;
	border-bottom: 0.1px solid rgba(0, 0, 0, 0.518);
	width: 50%;
	font-size: 14px;
}

#dark.card-member-info {
	background-color: rgb(24, 25, 28);
}

#light.card-member-info {
	background-color: #ffffff;
}

#purple.card-member-info {
	background-color: #ffffff;
}

.member-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 300px;
	align-items: flex-end;
}

.add-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: green;
	margin-bottom: 12px;
	margin-right: 2px;
}

#purple.add-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: #4c4087;
	margin-bottom: 12px;
	margin-right: 2px;
}

.delete-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: red;
	margin-bottom: 12px;
	margin-right: 2px;
}

.card-member-inner-div {
	/* width: calc(100% - 12px); */
	width: 100%;
}

.member-nickName-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 5px;
	padding-bottom: 10px;
	color: white;
	height: -webkit-max-content;
	height: max-content;
	width: 100%;
}

#dark.member-nickName-div {
	color: white;
	border-bottom: 1px solid #292b2f;
}

#light.member-nickName-div {
	color: #060607;
	border-bottom: 1px solid #e7e8eb;
}

#purple.member-nickName-div {
	color: #5f50a9;
	border-bottom: 1px solid #e7e8eb;
}

.member-nickname-div-container {
	width: 100%;
}

.member-nickname-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-overflow: ellipsis;
	width: calc(100% - 40px);
}

.nickname-edit-button {
	cursor: pointer;
	width: 32px;
}

.member-nickname {
	width: calc(100% - 32px);
	/* height: 28px; */
	font-weight: 700;
	font-size: 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#dark.member-nickname {
	color: #fff;
}

#light.member-nickname {
	color: #060607;
}

#purple.member-nickname {
	color: #5f50a9;
}

.member-since-section {
	padding: 5px 0;
}

.role-section {
	padding: 5px 0;
	height: 75px;
	flex-grow: 1;
}

.member-h4 {
	text-transform: uppercase;
	font-size: 13px;
	padding: 5px 0;
}

#dark.member-h4 {
	color: #fff;
}

#light.member-h4 {
	color: #060607;
}

#purple.member-h4 {
	color: #5f50a9;
}

.member-role-div {
	display: flex;
	flex-direction: row;
	font-size: 14px;
	/* border-radius: 4px; */
	/* padding: 2px 6px; */
	box-sizing: border-box;
	width: -webkit-max-content;
	width: max-content;
	justify-content: flex-start;
	align-items: center;
	/* background-color: #282b2f; */
	grid-gap: 4px;
	gap: 4px;
}

#dark.member-role-div {
	color: white;
}

#light.member-role-div {
	color: #313338;
}

#owner.member-role-div,
#member.member-role-div,
#pending.member-role-div {
	display: flex;
	flex-direction: row;
	color: white;
	font-size: 14px;
	/* border-radius: 4px; */
	/* padding: 2px 6px; */
	box-sizing: border-box;
	width: -webkit-max-content;
	width: max-content;
	justify-content: flex-start;
	align-items: center;
	/* background-color: #282b2f; */
	grid-gap: 4px;
	gap: 4px;
}

#dark#owner.member-role-div,
#dark#member.member-role-div,
#dark#pending.member-role-div {
	color: white;
}

#light#owner.member-role-div,
#light#member.member-role-div,
#light#pending.member-role-div {
	color: #e9eaed;
}

.member-role-container {
	border-radius: 4px;
	padding: 3px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
}

#dark.member-role-container {
	background-color: #282b2f;
}

#light.member-role-container {
	background-color: #e9eaed;
}

#purple.member-role-container {
	background-color: #bfb9dd;
}

.member-role-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

#owner.member-role-circle,
#admin.member-role-circle {
	background-color: rgb(25, 188, 155);
}

#member.member-role-circle {
	background-color: rgb(196, 122, 228);
}

#pending.member-role-circle {
	background-color: rgb(255, 255, 255);
}

.member-role {
	/* padding-right: 5px; */
	padding-bottom: 1px;
	font-size: 14px;
}

#dark.member-role {
	color: #e0e0e0;
}

#light.member-role {
	color: #313338;
}

#purple.member-role {
	color: #fff;
}

/* 7f73ba */

.role-edit-button {
	border-radius: 4px;
	padding: 2px 8px;
	cursor: pointer;
	font-size: 16px;
	/* background-color: #292b2f; */
	/* padding: 0 5px; */
	/* padding-bottom: 1px; */
}

#dark.role-edit-button {
	color: #e0e0e0;
	background-color: #282b2f;
}

#light.role-edit-button {
	background-color: #e9eaed;
}

#purple.role-edit-button {
	color: #fff;
	background-color: #bfb9dd;
}

.role-edit-button:hover {
	background-color: #292b2f;
}

.card-text {
	font-size: 13px;
}

#dark.card-text {
	color: white;
}

#light.card-text {
	color: #313338;
}

#purple.card-text {
	color: #9f96cb;
}

.leave-server-div {
	padding: 20px 0;
	padding-top: 60px;
}

.delete-membership-button {
	border: none;
	background-color: red;
	color: white;
	border-radius: 5px;
	padding: 3px 5px;
}

.delete-tooltip {
	position: relative;
	display: inline-block;
}

.delete-tooltip .delete-tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -60px;
}

.delete-tooltip .delete-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.delete-tooltip:hover .delete-tooltiptext {
	visibility: visible;
}

.dms-admin {
	color: rgb(103, 223, 147);
}

.dms-member {
	color: rgb(196, 122, 228);
}

.cms-friend-name {
	/* width: 240px; */
	padding-right: 12px;
}

.cms-container.dms {
	width: 100%;
	/* width: calc(100% - 312px); */
}

.cm-form-container.dms {
	box-sizing: border-box;
	width: 100%;
}

/* .mleft-10 {
	padding-left: 10px;
} */

.dm-row {
	width: 80%;
	/* display: flex;
	flex-direction: row; */
}

.dm-aka {
	background-color: rgba(0, 0, 0, 0.096);
	border-radius: 10px;
	padding: 3px 7px 0px 7px;
	font-size: 10px;
}

.overfl-wid {
	width: calc(100% - 240px);
	padding-left: 10px;
	border-left: solid 1px rgba(255, 255, 255, 0.13);
	/* overflow-x: hidden; */
	height: 22px;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: clip;
	text-overflow: ellipsis;
}

.dm-names {
	color: rgba(255, 255, 255, 0.507);
	font-size: 14px;
	padding-left: 10px;
}

#purple.dm-names {
	color: #864f8b;
}
#light.dm-names {
	color: #00000097;
}

.empty-friend-pic {
	height: 80px;
	width: 80px;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 20px;
}

.server-members-div {
	display: flex;
	flex-direction: column;
	background-color: rgb(48, 49, 54);
	height: calc(100% - 46px);
	/* height: 100%; */
	width: 230px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.ServerMember-sidebar {
	width: 240px;
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	-webkit-user-select: none;
	        user-select: none;
}

#dark.ServerMember-sidebar {
	background-color: #36393f;
}

#light.ServerMember-sidebar {
	background-color: #ffffff;
}

#purple.ServerMember-sidebar {
	background-color: #7f73ba;
}

.ServerMember-content {
	margin-top: 1px;
	display: flex;
	flex-direction: column;
	height: calc(100% - 48px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

#dark.ServerMember-content {
	background-color: #303136;
}

#light.ServerMember-content {
	background-color: #eeeff1;
}

#purple.ServerMember-content {
	background-color: #7f73ba;
}

.join-server-div {
	background-color: rgb(60, 68, 165);
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 0;
}

.join-text {
	color: white;
	font-size: 12px;
	padding-right: 5px;
}

.join-server-button {
	background-color: rgb(60, 68, 165);
	color: white;
	border: 1.5px solid white;
	font-size: 12px;
	padding: 3px 7px;
	margin: 3px;
	border-radius: 3px;
	overflow: hidden;
}

.server-members-header {
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	/* margin-top: 5px; */
	box-sizing: border-box;
	border-bottom: 1px;
	color: #ffffff;
	box-shadow: 0 1px 0 hsl(240 11.1% 1.8% / 0.2),
		0 1.5px 0 hsl(240 7.7% 2.5%/0.05), 0 2px 0 hsl(240 11.1% 1.8%/0.05);
}

.total-members {
	font-size: 12px;
	text-transform: uppercase;
	margin-top: 20px;
	margin-left: 10px;

	margin-top: 0px;
	padding-top: 0px;
	visibility: hidden;
}

#dark.total-members {
	color: rgba(211, 212, 214, 0.8);
}

#light.total-members {
	color: #5c5e66;
}

#purple.total-members {
	color: #fff;
}


/* h2 {
	width: 230px;
	padding-left: 5px;
	padding-bottom: 3px;
	font-size: 12px;
	color: rgb(211, 212, 214, 0.8);
} */

/* .owner.nicknames {
	color: rgb(103, 223, 147);
}

.admin.nicknames {
	color: rgb(103, 223, 147);
}

.member.nicknames {
	color: rgb(196, 122, 228);
}

.pending.nicknames {
	color: white;
} */

.nicknames {
	font-size: 15px;
}

.individual-person button {
	/* font-size: 15px; */
	color: rgb(25, 188, 155);
	border: none;
}

#dark #owner.individual-person button {
	/* color: rgb(103, 223, 147); */
	color: rgb(25, 188, 155);
}

#dark #admin.individual-person button {
	/* color: rgb(103, 223, 147); */
	color: rgb(25, 188, 155);
}

#light #owner.individual-person button {
	color: #49c193;
}

#light #admin.individual-person button {
	color: #49c193;
}

#purple #owner.individual-person button,
#purple #admin.individual-person button {
	color: rgb(42, 129, 95);
}

#dark #member.individual-person button {
	color: rgb(196, 122, 228);
}

#light #member.individual-person button {
	color: #9b59b6;
}

#purple #member.individual-person button {
	color: #bfb9dd;
}

#dark #pending.individual-person button {
	color: white;
}

#light #pending.individual-person button {
	color: #5c5e66;
}

#purple #pending.individual-person button {
	color: #5c5e66;
}

/* #owner.individual-person button:hover {
  background-color: #3f434a;
}

#admin.individual-person button:hover {
  background-color: #3f434a;
}

#member.individual-person button:hover {
  background-color: #3f434a;
}

#pending.individual-person button:hover {
  background-color: #3f434a;
} */

/* #dddee2 */
/* .individual-person button:hover {
  background-color: #3f434a;
  filter: brightness(1);
  cursor: pointer;
} */

.visible {
	position: absolute;
	right: 100px;
}

.hidden {
	display: none;
}

.section {
	padding: 5px 0;
}

.individual-person {
	/* padding: 5px 0; */
	padding: 5px 10px;
	margin-left: 2px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	width: 205px;
}

#dark .individual-person:hover {
	background-color: #3f434a;
	-webkit-filter: brightness(1);
	        filter: brightness(1);
}

#light .individual-person:hover {
	background-color: #dddee2;
	-webkit-filter: brightness(1);
	        filter: brightness(1);
}

#purple .individual-person:hover {
	background-color: #4c4087;
	-webkit-filter: brightness(1);
	        filter: brightness(1);
}

.member-img {
	border-radius: 50%;
	height: 35px;
	width: 35px;
	object-fit: cover;
}

.pending {
	-webkit-filter: brightness(0.5);
	        filter: brightness(0.5);
}

.server-owner-crown {
	height: 12px;
	padding-top: 2px;
	padding-left: 5px;
}

.owner-div.section,
.admin-div.section,
.regular-members-div.section,
.pending-div.section {
	padding-left: 5px;
	width: calc(100% - 10px);
}

.ServerMembers-headers {
	/* width: 100%; */
	margin-left: 12px;
	margin-bottom: 3px;
	/* padding-left: 5px;
  padding-bottom: 3px; */
	font-weight: normal;
	text-transform: uppercase;
	font-size: 11px;
}

#dark.ServerMembers-headers {
	color: rgb(211, 212, 214, 0.8);
}

#light.ServerMembers-headers {
	color: #5c5e66;
}

#purple.ServerMembers-headers {
	color: #fff;
}

h2 {
	padding: 0;
	margin: 0;
}

.edit-nickname-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 28px;
}

.nickname-input {
    background-color: #3f434a;
    color: white;
    border: none;
    border-radius: 4px;
    padding-left: 4px;
    height: 28px;
}

#purple.nickname-input {
    color: #7f73ba;
    background-color: #e9eaed;
}

.save-nickName-button {
    border: none;
    font-family: "Roboto";
}

#dark.save-nickName-button {
    background-color: rgb(24,25,28);
    color: white;
}

#light.save-nickName-button {
    background-color: #ffffff;
    color: #060607;
}

#purple.save-nickName-button {
    background-color: #ffffff;
    color: #5F50A9;
}

.save-nickName-button {
    font-size: 11px;
}

.edit-role-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.role-input {
    border: none;
    border-radius: 4px;
    padding: 3px;
}

#dark.role-input {
    background-color: #3f434a;
    color: white;
}

#light.role-input {
    background-color: #e9eaed;
    color: #313338;
}

#purple.role-input {
    background-color: #bfb9dd;
    color: #fff;
}

.role-button {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-left: 5px;g */
    grid-gap: 8px;
    gap: 8px;
}

.save-role-button,
.cancel-role-button {
    border: none;
    border-radius: 4px;
    /* background-color: rgb(24,25,28); */
    font-family: "Roboto";
    font-size: 15px;
    width: 70px;
    padding: 3px;
    /* margin-top: 8px; */
}

/* .cancel-role-button {
    border: none;
    color: white;
    background-color: rgb(24,25,28);
    background-color: #3f434a;
    font-family: "Roboto";
    font-size: 15px;
    width: 70px;
    margin-top: 8px;
} */

#dark.save-role-button,
#dark.cancel-role-button {
    color: white;
    background-color: #3f434a;
}

#light.save-role-button,
#light.cancel-role-button {
    background-color: #e9eaed;
    color: #313338;
}

#purple.save-role-button,
#purple.cancel-role-button {
    background-color: #bfb9dd;
    color: #fff;
}

.member-card {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	height: 450px;
	width: 350px;
}

#dark.member-card {
	background: #292b2f;
}

#light.member-card {
	background: #e9eaed;
}

#purple.member-card {
	background: #dfdcee;
}

#card-header {
	margin: 0;
	border-radius: 10px 10px 0 0;
	height: 65px;
	width: 100%;
	background: #000;
}

.card-img {
	width: 90px;
	height: 90px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
	border-radius: 50%;
	object-fit: cover;
}

#dark.card-img {
	border: 5px solid #292b2f;
}

#light.card-img {
	border: 5px solid #e9eaed;
}

#purple.card-img {
	border: 5px solid #dfdcee;
}

.card-content {
	border-radius: 50%;
	margin: -45px 20px;
	width: 80%;
	height: 100px;
}

.card-member-info {
	display: flex;
	flex-direction: column;
	height: 300px;
	width: 300px;
	border-radius: 10px;
	/* font-family: "Segoe UI Variable"; */
	font-family: "Roboto";
	box-sizing: border-box;
	padding: 12px;
}

#dark.card-member-info {
	background-color: rgb(24, 25, 28);
}

#light.card-member-info {
	background-color: #ffffff;
}

#purple.card-member-info {
	background-color: #ffffff;
}

.member-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 300px;
	align-items: flex-end;
}

.add-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: green;
	margin-bottom: 12px;
	margin-right: 2px;
}

#purple.add-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: #4c4087;
	margin-bottom: 12px;
	margin-right: 2px;
}

.delete-friend-button {
	border: none;
	border-radius: 3px;
	height: 30px;
	width: 110px;
	font-size: 12px;
	color: white;
	background-color: red;
	margin-bottom: 12px;
	margin-right: 2px;
}

.card-member-inner-div {
	/* width: calc(100% - 12px); */
	width: 100%;
}

.member-nickName-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 5px;
	padding-bottom: 10px;
	color: white;
	height: -webkit-max-content;
	height: max-content;
	width: 100%;
}

#dark.member-nickName-div {
	color: white;
	border-bottom: 1px solid #292b2f;
}

#light.member-nickName-div {
	color: #060607;
	border-bottom: 1px solid #e7e8eb;
}

#purple.member-nickName-div {
	color: #5f50a9;
	border-bottom: 1px solid #e7e8eb;
}

.member-nickname-div-container {
	width: 100%;
}

.member-nickname-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-overflow: ellipsis;
	width: calc(100% - 40px);
}

.nickname-edit-button {
	cursor: pointer;
	width: 32px;
}

.member-nickname {
	width: calc(100% - 32px);
	/* height: 28px; */
	font-weight: 700;
	font-size: 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#dark.member-nickname {
	color: #fff;
}

#light.member-nickname {
	color: #060607;
}

#purple.member-nickname {
	color: #5f50a9;
}

.member-since-section {
	padding: 5px 0;
}

.role-section {
	padding: 5px 0;
	height: 75px;
	flex-grow: 1;
}

.member-h4 {
	text-transform: uppercase;
	font-size: 13px;
	padding: 5px 0;
}

#dark.member-h4 {
	color: #fff;
}

#light.member-h4 {
	color: #060607;
}

#purple.member-h4 {
	color: #5f50a9;
}

.member-role-div {
	display: flex;
	flex-direction: row;
	font-size: 14px;
	/* border-radius: 4px; */
	/* padding: 2px 6px; */
	box-sizing: border-box;
	width: -webkit-max-content;
	width: max-content;
	justify-content: flex-start;
	align-items: center;
	/* background-color: #282b2f; */
	grid-gap: 4px;
	gap: 4px;
}

#dark.member-role-div {
	color: white;
}

#light.member-role-div {
	color: #313338;
}

#owner.member-role-div,
#member.member-role-div,
#pending.member-role-div {
	display: flex;
	flex-direction: row;
	color: white;
	font-size: 14px;
	/* border-radius: 4px; */
	/* padding: 2px 6px; */
	box-sizing: border-box;
	width: -webkit-max-content;
	width: max-content;
	justify-content: flex-start;
	align-items: center;
	/* background-color: #282b2f; */
	grid-gap: 4px;
	gap: 4px;
}

#dark#owner.member-role-div,
#dark#member.member-role-div,
#dark#pending.member-role-div {
	color: white;
}

#light#owner.member-role-div,
#light#member.member-role-div,
#light#pending.member-role-div {
	color: #e9eaed;
}

.member-role-container {
	border-radius: 4px;
	padding: 3px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
}

#dark.member-role-container {
	background-color: #282b2f;
}

#light.member-role-container {
	background-color: #e9eaed;
}

#purple.member-role-container {
	background-color: #bfb9dd;
}

.member-role-circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

#owner.member-role-circle,
#admin.member-role-circle {
	background-color: rgb(25, 188, 155);
}

#member.member-role-circle {
	background-color: rgb(196, 122, 228);
}

#pending.member-role-circle {
	background-color: rgb(255, 255, 255);
}

.member-role {
	/* padding-right: 5px; */
	padding-bottom: 1px;
	font-size: 14px;
}

#dark.member-role {
	color: #e0e0e0;
}

#light.member-role {
	color: #313338;
}

#purple.member-role {
	color: #fff;
}

/* 7f73ba */

.role-edit-button {
	border-radius: 4px;
	padding: 2px 8px;
	cursor: pointer;
	font-size: 16px;
	/* background-color: #292b2f; */
	/* padding: 0 5px; */
	/* padding-bottom: 1px; */
}

#dark.role-edit-button {
	color: #e0e0e0;
	background-color: #282b2f;
}

#light.role-edit-button {
	background-color: #e9eaed;
}

#purple.role-edit-button {
	color: #fff;
	background-color: #bfb9dd;
}

.role-edit-button:hover {
	background-color: #292b2f;
}

.card-text {
	font-size: 13px;
}

#dark.card-text {
	color: white;
}

#light.card-text {
	color: #313338;
}

#purple.card-text {
	color: #9f96cb;
}

.leave-server-div {
	padding: 20px 0;
	padding-top: 60px;
}

.delete-membership-button {
	border: none;
	background-color: red;
	color: white;
	border-radius: 5px;
	padding: 3px 5px;
}

.delete-tooltip {
	position: relative;
	display: inline-block;
}

.delete-tooltip .delete-tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -60px;
}

.delete-tooltip .delete-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.delete-tooltip:hover .delete-tooltiptext {
	visibility: visible;
}

.UserLanding-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.UserLanding-container-dm {
	width: calc(100%-314px);
	/* height: calc(100%-48px); */
}

.UserLanding-container.friend {
	display: flex;
	flex-direction: column;
}

#dark.UserLanding-container {
	background-color: #36393f;
}

#light.UserLanding-container {
	background-color: #ffffff;
}

#purple.UserLanding-container {
	background-color: #bfb9dd;
}

.reverse {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.UserLanding-main-content {
	display: flex;
	flex-direction: column;
	/* padding-top: 16px; */
	/* padding-right: 20px; */
	/* width: 1186px; */
	width: 100%;
	overflow-y: scroll;
}

.UserLanding-main-content {
	height: calc(100% - 48px);
}

.UserLanding-server-members {
	display: flex;
	flex-direction: column;
	/* height: calc(100% + 5px); */
	height: 100%;
	width: 230px;
}

.UserLanding-status {
	margin-bottom: 15px;
	padding: 0px 26px;
	padding-top: 16px;
}

.UserLanding-status > h2 {
	color: #b9bbbe;
	text-transform: uppercase;
	font-size: 12px;
}

#light.UserLanding-status > h2 {
	color: #535963;
}

#purple.UserLanding-status > h2 {
	color: #5f50a9;
}

.UserLanding-people-list-container {
	display: flex;
	flex-direction: column;
}

.UserLanding-people-list {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-top: 1px solid #41454c;
	margin: 0px 26px;
}

#light.UserLanding-people-list {
	border-top: 1px solid #dbdee1;
}

#purple.UserLanding-people-list {
	border-top: 1px solid #dbdee1;
}

.UserLanding-people-list:hover {
	background-color: #3f434a;
	border-radius: 5px;
}

#light.UserLanding-people-list:hover {
	background-color: #e7e9eb;
}

#purple.UserLanding-people-list:hover {
	background-color: #9f96cb44;
}

.UserLanding-user-info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 10px 0px;
}

.UserLanding-user-icon {
	margin-right: 12px;
}

.UserLanding-user-icon > img {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	object-fit: cover;
}

.UserLanding-user-name-status-container {
	display: flex;
	flex-direction: column;
	line-height: 16px;
}

.UserLanding-user-name {
	color: white;
	font-weight: bold;
	font-size: 15px;
}

#light.UserLanding-user-name {
	color: #060607;
}

#purple.UserLanding-user-name {
	color: #5f50a9;
}

.UserLanding-user-status {
	color: rgb(177, 177, 177);
	font-size: 13px;
	padding-top: 2px;
}

#light.UserLanding-user-status {
	color: #8e9298;
}

#purple.UserLanding-user-status {
	color: #5f50a9;
}

.UserLanding-user-actions-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.UserLanding-user-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 5px;
	color: #b9bbbe;
	background-color: #2f3136;
	height: 32px;
	width: 32px;
	text-align: center;
	border-radius: 50%;
}

#light.UserLanding-user-actions {
	background-color: #f2f3f5;
	color: #4f5660;
	display: flex;
	justify-content: center;
	align-items: center;
}

#purple.UserLanding-user-actions {
	background-color: #f2f3f5;
	color: #5f50a9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.UserLanding-header {
	width: inherit;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 6px;
	box-sizing: border-box;
	border-bottom: 1px;
	color: #ffffff;
	box-shadow: 0 1px 0 hsl(240 11.1% 1.8% / 0.2),
		0 1.5px 0 hsl(240 7.7% 2.5%/0.05), 0 2px 0 hsl(240 11.1% 1.8%/0.05);
	/* margin-bottom: 16px; */
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

#remove-friend-icon {
	cursor: pointer;
	font-size: 13px;
	padding-bottom: 6px;
}

:root {
	--primary-dark-900-hsl: 240 calc(1 * 11.1%) 1.8%;
	--primary-light-900-hsl: 240 calc(1 * 7.7%) 2.5%;
}

.UserLanding-sidebar {
	width: 240px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	-webkit-user-select: none;
	        user-select: none;
}

#dark.UserLanding-sidebar {
	background-color: #303136;
}

#light.UserLanding-sidebar {
	background-color: #f2f3f5;
}

#purple.UserLanding-sidebar {
	background-color: #7f73ba;
}

.UserLanding-sidebar-header {
	width: inherit;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	box-sizing: border-box;
	border-bottom: 1px;
	box-shadow: 0 1px 0 hsl(240 11.1% 1.8% / 0.2),
		0 1.5px 0 hsl(240 7.7% 2.5%/0.05), 0 2px 0 hsl(240 11.1% 1.8%/0.05);
}

#dark.UserLanding-sidebar-header {
	color: #ffffff;
}

#light.UserLanding-sidebar-header {
	color: #060607;
}

#purple.UserLanding-sidebar-header {
	color: #393065;
}

.UserLanding-sidebar-channel-content {
	height: calc(100% - 98px);
	overflow-y: scroll;
}

.UserLanding-sidebar-channel-category-container {
	padding-top: 16px;
	color: #96989d;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* width: 240px; */
}

#light.UserLanding-sidebar-channel-category-container {
	color: #5e6772;
}

#purple.UserLanding-sidebar-channel-category-container {
	color: #c8c7d1;
}

.UserLanding-sidebar-channel-category-name {
	width: 195px;
}

.fa-angle-down {
	font-size: 8px;
	padding: 4px;
}

.big-angle-down {
	font-size: 14px;
}

.align-right {
	justify-self: right;
	font-size: 15px;
}

#light.align-right {
	color: red;
}

.UserLanding-sidebar-channel-list {
	width: 100%;
	box-sizing: border-box;
	color: #96989d;
	display: flex;
	flex-direction: column;
	padding: 8px;
	overflow-y: auto;
}

.hash {
	font-weight: bold;
}

.UserLanding-sidebar-channel-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.UserLanding-sidebar-channel-name {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 34px;
	color: #96989d;
	cursor: pointer;
	padding: 7px;
	box-sizing: border-box;
	font-size: 16px;
}

#purple.UserLanding-sidebar-channel-name {
	color: #fff;
}

a.UserLanding-sidebar-channel-name {
	text-decoration: none;
}

.UserLanding-sidebar-channel-name.active {
	color: #ffffff;
	cursor: pointer;
	background-color: #43474e;
	text-decoration: none;
	border-radius: 4px;
}

#light.UserLanding-sidebar-channel-name.active {
	color: #303031;
	background-color: #d1d5da;
}

#purple.UserLanding-sidebar-channel-name.active {
	color: #4c4087;
	background-color: #bfb9dd;
}

.UserLanding-sidebar-channel-name:hover {
	background-color: #3c4045;
	border-radius: 4px;
}

#light.UserLanding-sidebar-channel-name:hover {
	background-color: #d1d5da;
}

#purple.UserLanding-sidebar-channel-name:hover {
	background-color: #bfb9dd3a;
}

.UserLanding-sidebar-channel-buttons {
	display: flex;
	align-items: center;
	padding-right: 20px;
	/* gap: 8px; */
}

#purple.UserLanding-sidebar-channel-buttons {
	color: #dfdcee;
}

.fa-user-plus,
.fa-gear {
	font-size: 12px;
}

.UserLanding-sidebar-channel-user-info {
	position: absolute;
	bottom: 0;
	width: 240px;
	height: 52px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.UserLanding-sidebar-yingyang {
	width: 100%;
	padding: 8px;
	background: transparent;
	border: none;
	color: #b9bbbe;
	text-align: left;
}

.UserLanding-sidebar-yingyang:hover {
	width: 100%;
	background: lightgrey;
	border: none;
	color: #2a2b2f;
	text-align: left;
	border-radius: 3px;
}

#dark.UserLanding-sidebar-channel-user-info {
	background-color: #2a2b2f;
}

#light.UserLanding-sidebar-channel-user-info {
	background-color: #ebedef;
}

#purple.UserLanding-sidebar-channel-user-info {
	background-color: #675c9f;
}

.UserLanding-sidebar-channel-user-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 122px;
	box-sizing: border-box;
	grid-gap: 8px;
	gap: 8px;
	padding-left: 8px;
}

.UserLanding-sidebar-channel-user-icons {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding-left: 2px; */
	overflow: hidden;
}

.UserLanding-sidebar-channel-user-icon {
	width: 100px;
	aspect-ratio: 1;
	border-radius: 50%;
	object-fit: cover;
}

.UserLanding-sidebar-channel-user-name {
	width: calc(100% - 40px);
	font-size: 12px;
	color: #ffffff;
}

#light.UserLanding-sidebar-channel-user-name {
	color: #060607;
}

#purple.UserLanding-sidebar-channel-user-name {
	color: #fff;
}

.UserLanding-sidebar-channel-user-actions {
	color: #96989d;
	display: flex;
	align-items: center;
	grid-gap: 14px;
	gap: 14px;
	padding-right: 14px;
}

#purple.UserLanding-sidebar-channel-user-actions {
	color: #dfdcee;
}

.user-gear {
	font-size: 16px;
	cursor: pointer;
	/* margin-left: 30px; */
}

.user-ying-yang {
	cursor: pointer;
}

.UserLanding-sidebar-channel-user-home,
.UserLanding-sidebar-channel-user-logout {
	width: 100%;
	padding: 8px;
	background: transparent;
	border: none;
	color: #b9bbbe;
	text-align: left;
}

.UserLanding-sidebar-channel-user-home:hover,
.UserLanding-sidebar-channel-user-logout:hover {
	width: 100%;
	background: lightgrey;
	border: none;
	color: #2a2b2f;
	text-align: left;
}

.UserLanding-sidebar-channel-user-home:hover {
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.UserLanding-sidebar-channel-user-logout:hover {
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.settings-tooltip,
.themes-tooltip {
	position: relative;
	display: inline-block;
}

.settings-tooltip .settings-tooltiptext {
	visibility: hidden;
	width: -webkit-max-content;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -47px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.themes-tooltip .themes-tooltiptext {
	visibility: hidden;
	width: -webkit-max-content;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -53px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.settings-tooltip .settings-tooltiptext::after,
.themes-tooltip .themes-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.settings-tooltip:hover .settings-tooltiptext,
.themes-tooltip:hover .themes-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.friends-tooltip {
	position: relative;
	display: inline-block;
}

.friends-tooltip .friends-tooltiptext {
	visibility: hidden;
	width: 60px;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -37px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.friends-tooltip .friends-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.friends-tooltip:hover .friends-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.sliding-tooltip,
.surrender-tooltip {
	position: relative;
	display: inline-block;
}

.surrender-tooltip .surrender-tooltiptext {
	visibility: hidden;
	width: 80px;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 3px 0;
	position: absolute;
	z-index: 1;
	bottom: 155%;
	left: 34px;
	margin-left: -68px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.sliding-tooltip .sliding-tooltiptext {
	visibility: hidden;
	width: -webkit-max-content;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	position: absolute;
	z-index: 1;
	bottom: 145%;
	left: 50%;
	margin-left: -52px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 13px;
}

.sliding-tooltip .sliding-tooltiptext::after,
.surrender-tooltip .surrender-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.sliding-tooltip:hover .sliding-tooltiptext,
.surrender-tooltip:hover .surrender-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.CreateChannelForm-container {
  width: 460px;
  /* padding: 0px 16px; */
  box-sizing: border-box;
}

#dark.CreateChannelForm-container {
  color: #ffffff;
  background-color: #35393e;
}

#light.CreateChannelForm-container {
  color: #060607;
  background-color: #ffffff;
}

#purple.CreateChannelForm-container {
  color: #5F50A9;
  background-color: #dfdcee;
}

.CreateChannelForm-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

#dark.fa-xmark,
#light.fa-xmark {
  color: #b9bbbe;
  transition: ease 0.2s;
}

#dark.fa-xmark:hover {
  color: #060707;
}

#light.fa-xmark {
  color: #acadb0;
  transition: ease 0.2s;
}

#light.fa-xmark:hover {
  color: #060707;
}

#purple.fa-xmark {
  color: #5F50A9;
}

#purple.fa-xmark:hover {
  color: #bfb9dd;
}

.CreateChannelForm-title {
  font-size: 20px;
}

#dark.CreateChannelForm-title {
  color: #ffffff;
}

#light.CreateChannelForm-title {
  color: #060607;
}

.CreateChannelForm-subtext,
.CreateChannelForm-label {
  font-size: 12px;
  color: #b9bbbe;
}

#dark.CreateChannelForm-subtext,
#dark.CreateChannelForm-label {
  font-size: 12px;
  color: #b9bbbe;
}

#light.CreateChannelForm-subtext,
#light.CreateChannelForm-label {
  font-size: 12px;
  color: #4e5058;
}

#purple.CreateChannelForm-subtext,
#purple.CreateChannelForm-label {
  color: #7f73ba;
}

.CreateChannelForm-label {
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.CreateChannelForm-group-channel-name {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
}

.CreateChannelForm-group-channel-input {
  display: flex;
  width: calc(100% - 16px);
  border: none;
  border-radius: 3px;
  /* color: #ffffff; */
  padding: 8px 10px 8px 8px;
  align-items: center;
}

#dark.CreateChannelForm-group-channel-input,
#dark.CreateChannelForm-group-channel-input > input {
  background-color: #202225;
  color: white;
}

#light.CreateChannelForm-group-channel-input,
#light#channel-name input {
  background-color: #e9eaed;
}

#purple.CreateChannelForm-group-channel-input,
#purple#channel-name input {
  background-color: #e9eaed;
}

.CreateChannelForm-group-channel-input > input {
  width: 100%;
  background-color: transparent;
  border: 0;
}

#dark.CreateChannelForm-group-channel-input > input::-webkit-input-placeholder {
  color: #7c7f82;
}

#dark.CreateChannelForm-group-channel-input > input::placeholder {
  color: #7c7f82;
}

#light.CreateChannelForm-group-channel-input > input::-webkit-input-placeholder {
  color: #060607;
}

#light.CreateChannelForm-group-channel-input > input::placeholder {
  color: #060607;
}

#purple.CreateChannelForm-group-channel-input > input::-webkit-input-placeholder {
  color: #bfb9dd;
}

#purple.CreateChannelForm-group-channel-input > input::placeholder {
  color: #bfb9dd;
}

.hashtag {
  margin-right: 8px;
}

#dark.hashtag {
  color: #ffffff;
}

#light.hashtag {
  color: #060607;
}

/* .CreateChannelForm-group-channel-name > label::placeholder {
    width: 100%;
    border-radius: 3px;
    color: #7c7f82;
} */

#dark.CreateChannelForm-group-private-span {
  color: #060607;
}

#light.CreateChannelForm-group-private-span {
  color: #060607;
}

#purple.CreateChannelForm-group-private-span {
  color: #5F50A9;
}

.fa-lock {
  padding-right: 8px;
}

#dark.fa-lock {
  color: #b9bbbe;
}

#light.fa-lock {
  color: #777778;
}

#purple.fa-lock {
  color: #5F50A9;
}

.CreateChannelForm-group-private {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding: 0px 16px;
}

.CreateChannelForm-private-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.CreateChannelForm-private-switch input {
  display: none;
}

.CreateChannelForm-private-slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 30px;
}

.CreateChannelForm-private-slider-round:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .CreateChannelForm-private-slider-round {
  background-color: #2196f3;
}

input:focus + .CreateChannelForm-private-slider-round {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .CreateChannelForm-private-slider-round:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.CreateChannelForm-private-text {
  color: #b9bbbe;
  font-size: 12px;
  padding: 0px 16px;
}

#dark.CreateChannelForm-private-text {
  color: #b9bbbe;
}

#light.CreateChannelForm-private-text {
  color: #4e5058;
}

#purple.CreateChannelForm-private-text {
  color: #4e5058;
}

.CreateChannelForm-buttons-container {
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: right;
  padding: 16px;
  margin-top: 16px;
}

#dark.CreateChannelForm-buttons-container {
  background-color: #2f3136;
}

#light.CreateChannelForm-buttons-container {
  background-color: #f3f4f5;
}

#purple.CreateChannelForm-buttons-container {
  background-color: #bfb9dd;
}

.CreateChannelForm-button-cancel {
  font-weight: 600;
  font-size: 13px;
  border: 0;
  background-color: transparent;
  padding: 11px 16px;
}

#dark.CreateChannelForm-button-cancel {
  color: #ffffff;
  color: #82858f;
}

#light.CreateChannelForm-button-cancel {
  color: #82858f;
}

#purple.CreateChannelForm-button-cancel {
  color: #ffffff;
}

.CreateChannelForm-button-create {
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  border: 0;
  background-color: #5865f2;
  padding: 11px 16px;
  border-radius: 4px;
}

#purple.CreateChannelForm-button-create {
  background-color: #7f73ba;
}

.CreateChannelForm-button-create:hover {
  background-color: #3c4beb;
}

#purple.CreateChannelForm-button-create:hover {
  background-color: #4c4087;
}

.CreateChannelForm-button-delete {
  color: red;
  font-weight: 600;
  font-size: 13px;
  border: 0;
  background-color: transparent;
  padding: 11px 16px;
  border-radius: 4px;
}

.CreateChannelForm-checkbox-container {
  display: grid;
  grid-gap: 8px;
  gap: 8px;
  grid-template-columns: -webkit-max-content -webkit-min-content;
  grid-template-columns: max-content min-content;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  align-items: center;
}

.CreateChannelForm-checkbox-container > input,
.CreateChannelForm-checkbox-container > div {
  grid-column: 2/3;
  grid-row: 1/2;
}

.CreateChannelForm-checkbox {
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
  padding: 0;
}

.CreateChannelForm-switch {
  position: relative;
  background: #70747a;
  transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
  font-size: 1em;
  width: 2em;
  height: 1em;
  padding: 5px;
  border-radius: 9999px;
  pointer-events: none;
}

#purple.CreateChannelForm-switch {
  background-color: #7f73ba;
}

.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch {
  background-color: #44b27e;
}

.CreateChannelForm-checkbox ~ .CreateChannelForm-switch > div {
  position: relative;
  left: 0;
  height: 0.65em;
  width: 0.65em;
  background: white;
  pointer-events: none;
  transition: inherit;
  border-radius: 9999px;

  display: grid;
  justify-items: stretch;
  align-items: stretch;
  padding: 0.2em;
}

.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch > div {
  left: calc(100%);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background: white;
  display: grid;
}

.CreateChannelForm-checkbox ~ .CreateChannelForm-switch > div:before,
.CreateChannelForm-checkbox ~ .CreateChannelForm-switch > div:after {
  content: "";
  transition: inherit;
  background-color: #70747a;
  grid-column: 1/2;
  grid-row: 1/2;
}

.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch > div:before,
.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch > div:after {
  background-color: #44b27e;
}

.CreateChannelForm-checkbox ~ .CreateChannelForm-switch > div:before {
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 80% 100%, 100% 80%);
          clip-path: polygon(20% 0%, 0% 20%, 80% 100%, 100% 80%);
}

.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch > div:before {
  -webkit-clip-path: polygon(25% 75%, 33% 100%, 100% 20%, 80% 0%);
          clip-path: polygon(25% 75%, 33% 100%, 100% 20%, 80% 0%);
}

.CreateChannelForm-checkbox ~ .CreateChannelForm-switch > div:after {
  -webkit-clip-path: polygon(0% 80%, 20% 100%, 100% 20%, 80% 0%);
          clip-path: polygon(0% 80%, 20% 100%, 100% 20%, 80% 0%);
}

.CreateChannelForm-checkbox:checked ~ .CreateChannelForm-switch > div:after {
  -webkit-clip-path: polygon(20% 45%, 0% 65%, 33.33% 100%, 45% 75%);
          clip-path: polygon(20% 45%, 0% 65%, 33.33% 100%, 45% 75%);
}

#modal-content {
  border-radius: 12px;
}

.EditDeleteChannelModal-container {
  position: absolute;
  width: calc(100vw + 72px);
  height: 100vh;
  z-index: 99999999999;
  background-color: #36393f;
  margin-left: -72px;
  padding-left: 10px;
  display: flex;
  color: #96989d;
}

.hidden {
  display: none;
}

.EditDeleteChannelModal-left {
  display: flex;
  justify-content: flex-end;
  width: 35%;
  padding-top: 72px;
}

.EditDeleteChannelModal-nav {
  display: flex;
  flex-direction: column;
  width: 218px;
}

.EditDeleteChannelModal-nav-headers {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.EditDeleteChannelModal-nav-header {
  font-size: 11px;
  color: #96989d;
  text-transform: uppercase;
  font-weight: 600;
}

.EditDeleteChannelModal-right {
  padding-top: 72px;
}

.EditDeleteChannelModal-nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  color: #96989d;
  cursor: pointer;
  padding: 7px;
  box-sizing: border-box;
  font-size: 16px;
}

.CreateChannelForm-button-delete:disabled {
  color: #96989d;
}

.CreateChannelForm-button-delete:disabled:hover {
  color: #bfc2c9;
}

.CreateChannelForm-button-delete.tooltip {
  position: relative;
  display: inline-block;
}

.EditChannelForm-button-delete-text {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #18191c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #18191c transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* .CreateChannelForm-button-delete:hover .EditChannelForm-button-delete-hover {
    display: block;
    position: absolute;
} */

.UserLanding-sidebar-channel-name {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 34px;
	color: #96989d;
	cursor: pointer;
	padding: 7px;
	box-sizing: border-box;
	font-size: 16px;
	align-items: center;
	text-overflow: ellipsis;
	width: 100%;
}

.server-dropdown-button {
	border: none;
	background-color: transparent;
}

#dark.server-dropdown-button {
	color: white;
}

#light.server-dropdown-button {
	color: #060607;
}

#purple.server-dropdown-button {
	color: #393065;
}

.hidden {
	display: none;
}

.server-menu-dropdown {
	z-index: 1;
	background-color: black;
	position: absolute;
	top: 55px;
	left: 82px;
	box-shadow: 0 2px 16px rgb(0 0 0 / 12%);
	border-radius: 3px;
	width: 200px;
	padding: 10px;
}

.server-dropdown-button:hover {
	cursor: pointer;
}

.dropdown-wrapper {
	display: flex;
	flex-direction: column;
}

.server-dropdown-edit {
	font-size: 14px;
	color: #b9bbbe;
	margin-bottom: 10px;
}

.server-dropdown-edit:hover {
	color: white;
}

.OpenModaldiv-edit-button {
	display: flex;
	justify-content: space-between;
}

.OpenModaldiv-delete-button {
	display: flex;
	justify-content: space-between;
}

.OpenModaldiv-edit-button:hover,
.OpenModaldiv-delete-button:hover {
	cursor: pointer;
}

.server-dropdown-delete {
	font-size: 14px;
	color: rgb(255, 107, 107);
}

.server-dropdown-delete:hover {
	color: red;
}

.UserLanding-Sidebar-user-dropdown {
	z-index: 1;
	background-color: black;
	position: absolute;
	top: -60px;
	left: 158px;
	box-shadow: 0 2px 16px rgb(0 0 0 / 12%);
	border-radius: 3px;
	width: 120px;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	font-size: 14px;
	color: #b9bbbe;
}

.UserLanding-Sidebar-yingyang-dropdown {
	z-index: 1;
	background-color: black;
	position: absolute;
	top: -95px;
	left: 125px;
	box-shadow: 0 2px 16px rgb(0 0 0 / 12%);
	border-radius: 3px;
	width: 120px;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	font-size: 14px;
	color: #b9bbbe;
}

.UserLanding-Sidebar-user-dropdown-user-logout {
	cursor: pointer;
}

a.UserLanding-sidebar-channel-user-home {
	text-decoration: none;
	font-size: 14px;
	color: #b9bbbe;
	cursor: pointer;
}

.hidden {
	display: none;
}

/* tooltip */
.edit-channel-tooltip {
	position: absolute;
	display: inline-block;
}

.edit-channel-tooltip .edit-channel-tooltiptext {
	visibility: hidden;
	width: -webkit-max-content;
	width: max-content;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	font-size: 13px;

	/* Position the tooltip */
	position: absolute;
	z-index: 999;
	bottom: 155%;
	left: 50%;
	margin-left: -46px;
}

.edit-channel-tooltip:hover .edit-channel-tooltiptext {
	visibility: visible;
}

.edit-channel-tooltip .edit-channel-tooltiptext::after {
	content: " ";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.channels-tooltip-container {
	padding-bottom: 18px;
	padding-right: 42.5px;
	text-transform: none;
}

.channels-tooltip {
	position: absolute;
	display: inline-block;
}

.channels-tooltip .channels-tooltiptext {
	visibility: hidden;
	width: -webkit-max-content;
	width: max-content;
	background-color: #18191c;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 8px;
	font-size: 13px;

	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -52px;
	opacity: 0;
	transition: opacity 0.3s;
}

.channels-tooltip .channels-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.channels-tooltip:hover .channels-tooltiptext {
	visibility: visible;
	opacity: 1;
}

.red {
	color: rgb(255, 106, 106);
	margin-right: 3px;
	cursor: pointer;
}

.mootro-only {
	margin-left: -453px;
}


.ConfirmDelete-header {
    padding: 16px;
}

.ConfirmDelete-header-title {
    color: #ffffff;
    font-size: 20px;
}

.ConfirmDelete-message {
    color: #ffffff;
    font-size: 14px;
    padding: 0px 8px 20px 16px;
}

.ConfirmDelete-message > span {
    font-weight: 600;
}


.delete-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  width: 420px;
  background-color: #36393f;
}

#light.delete-container {
  background-color: white;
}

.delete-header {
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 20px;
  margin-bottom: 5px;
}

#light.delete-header {
  color: black;
}

.delete-subtext {
  background-color: #faa81a;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px;
  margin: 0px 20px 20px;
}

.delete-subtext > span {
  font-weight: bold;
}

.delete-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: #2f3136;
  width: 100%;
  padding: 18px 0px;
}

#light.delete-buttons {
  background-color: #f3f4f5;
}

.delete-delete-button {
  margin-right: 20px;
  margin-left: 10px;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  color: white;
  background-color: #d83c3e;
  border: none;
}

.delete-delete-button:hover {
  background-color: #da2a2c;
}

.delete-cancel-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 13px;
}

#light.delete-cancel-button {
  color: #a5a7af;
}

#light.delete-cancel-button:hover {
  color: #8e9096;
}

.dm-channel-user-icon {
  height: 27px;
  width: 27px;
  border-radius: 55%;
  object-fit: cover;
}

.dm-icon-name-ct {
  display: flex;
  flex-direction: row;
}

.dm-username {
  margin-left: 15px;
}

.mleft-15 {
  margin: 15px;
}

.UserLanding-sidebar-channel-name.dmchannels {
  margin-top: 8px;
}

.DMChannels-header {
  padding: 8px;
}

.extra-pad {
  margin-bottom: 2px;
}

.friend-icon {
  margin-left: 2px;
}

.friends-margin {
  margin-left: 15px;
}

.MainContent-container {
  width: 100%;
  height: 100vh;
  background-color: #36393f;
  overflow-y: hidden;
  /* border: 5px solid green; */
  /* flex-grow: 1; */
}

.create-server-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 460px;
  background-color: #ffffff;
  box-sizing: border-box;
}

#purple.create-server-container {
  color: #5F50A9;
  background-color: #dfdcee;
}

.create-server-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px 0px;
}

.create-server-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
}

.create-server-subtext {
  text-align: center;
  color: #50565f;
}

#purple.create-server-subtext {
  color: #7f73ba;
}

.create-server-form-container {
  width: 430px;
}

.create-server-form-title {
  text-transform: uppercase;
  color: #50565f;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
}


#purple.create-server-form-title {
  color: #5F50A9;
}

.input-fields {
  font-size: 14px;
  padding: 5px 3px;
  width: 99%;
  border: none;
  border-radius: 2px;
  background-color: #e3e5e8;
  margin-bottom: 10px;
  resize: none;
}

#purple.input-fields {
  background-color: #e9eaed;
}

.input-fields:focus {
  outline: none;
}

.create-server-buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.create-server-button-cancel {
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: rgb(34, 34, 34);
  text-align: left;
  line-height: initial;
}

#purple.create-server-button-cancel {
  color: #5F50A9;
}

.create-server-button-submit {
  border: none;
  background-color: #5a65ea;
  color: white;
  padding: 12px 25px;
  border-radius: 3px;
}

.create-server-button-submit:hover {
  background-color: #3c4beb;
}

#purple.create-server-button-submit {
  background-color: #7f73ba;
}

#purple.create-server-button-submit:hover {
  background-color: #4c4087;
}

.create-server-button-cancel:hover {
  color: black;
}

#purple.create-server-button-cancel:hover {
  color: #bfb9dd;
}

.MainPage-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* overflow-y: hidden; */
  /* box-sizing: border-box; */
}

#dark.MainPage-container {
  background-color: #36393f;
}

#light.MainPage-container {
  background-color: white;
}

#purple.MainPage-container {
  background-color: #b5a9f0;
}

.explorer-container {
	display: flex;
	flex-direction: column;
	width: calc(100% - 72px);
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.servers-icon {
	height: 50px;
	width: 50px;
	border-radius: 25%;
	border: 5px solid #292b2f;
	object-fit: cover;
}

#light.servers-icon {
	border: 5px solid white;
}

.explorer-img {
	height: auto;
	width: 100%;
	max-height: 400px;
	object-fit: cover;
	border-radius: 9px;
}

.explorer-banner-container {
	margin: 15px 10px;
	/* width: 100%; */
}

.explorer-banner-header {
	font-size: 18px;
	font-weight: bold;
	margin: 15px 10px;
}

#light.explorer-banner-header {
	color: black;
}

#purple.explorer-banner-header {
	color: #5F50A9;
}

.exp-link {
	/* margin: auto; */
	cursor: pointer;
}

.exp-link:hover {
	color: rgb(255, 121, 121);
}

.exp-bk {
	width: 100%;
	/* height: 100%; */
	background-color: rgba(0, 0, 0, 0.866);
	color: white;
	display: flex;
	flex-direction: row;
}

#light.exp-bk {
	background-color: white;
}

#purple.exp-bk {
	background-color: #d6c5ff;
}

.explorer-banner-server-container {
	margin: 15px 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	word-wrap: break-word;
}

.explorer-server-container {
	position: relative;
	/* border: 1px solid white; */
	background-color: #292b2f;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 350px;
	height: 280px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: 0.5s;
	box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.3);
}

#light.explorer-server-container {
	background-color: rgb(243, 243, 243);
	border: 1px solid #e9e9eb;
}

#purple.explorer-server-container {
	background-color: #bfb9dd;
	border: 1px solid #e9e9eb;
}

.explorer-server-container:hover {
	background-color: #383b41;
	box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.75);
}

#light.explorer-server-container:hover {
	background-color: rgb(255, 255, 255);
}

#purple.explorer-server-container:hover {
	background-color: #dfdcee;
}

.explorer-server-description {
	display: flex;
	text-align: center;
}

.explorer-server-top {
	display: flex;
	/* align-items: center; */
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	justify-content: center;
	/* background: linear-gradient(
    140deg,
    rgba(145, 173, 159, 1) 0%,
    rgba(60, 70, 78, 1) 100%
  ) */
	/* background: linear-gradient(
    to right,
    rgba(236, 206, 206, 0.8),
    rgba(182, 170, 214, 0.8),
    rgba(145, 140, 170, 0.8)
  ); */
	background: linear-gradient(to right, #f6d365, #fda085);
	margin-bottom: 30px;
	height: 100px;
	width: 100%;
}

#light.explorer-server-top {
	background: linear-gradient(
		140deg,
		rgb(199, 236, 218) 0%,
		rgb(143, 167, 187) 100%
	);
}

#purple.explorer-server-top {
	background: linear-gradient(
		140deg,
		rgb(199, 236, 218) 0%,
		rgb(143, 167, 187) 100%
	);
}

.explorer-server-img {
	position: absolute;
	bottom: 155px;
}

.explorer-server-name {
	color: white;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	flex-direction: row;
}

#light.explorer-server-name {
	color: #060607;
}

#purple.explorer-server-name {
	color: #393065;
}

.check {
	color: green;
	margin-right: 5px;
}

#purple.check {
	color: lightgreen;
}

.explorer-server-description {
	color: #b6b6b6;
	font-size: 12px;
	margin-top: 7px;
	height: 10%;
}

#light.explorer-server-description {
	color: #97979c;
}

#purple.explorer-server-description {
	color: #5F50A9;
}

.join-server {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	margin-top: 15px;
	color: rgb(55, 137, 231);
	transition: 0.5s;
}

#purple.join-server {
	color: #393065;
}

.join-server:hover {
	color: rgb(109, 214, 255);
}

#purple.join-server:hover {
	color: #5F50A9;
}

.already-joined {
	text-transform: uppercase;
	font-size: 20px;
	margin-top: 10px;
}

#light.already-joined {
	color: black;
}

#purple.already-joined {
	color: fff;
}

.pointer {
	cursor: pointer;
}

/* TODO Add site wide styles */

/* temp styles */

/* .row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.mtop-15 {
	margin-top: 15px;
}

.small-icon {
	width: 25px;
	height: 25px;
	object-fit: cover;
	border-radius: 999px;
} */

/* TODO Add site wide styles */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto:wght@300;400&display=swap'); */

* {
  font-family: "Noto Sans", sans-serif;
}

.App-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #36393f; */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

/* Track */
/* ::-webkit-scrollbar-track { */
  /* background-color: #202225; */
/* } */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #202225;
  border-radius: 5px;
}

#light::-webkit-scrollbar-thumb,
#purple::-webkit-scrollbar-thumb {
  background: #97a0ad;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1b1c1f;
}

#light::-webkit-scrollbar-thumb:hover,
#purple::-webkit-scrollbar-thumb:hover {
  background: #868e99;
}

.Navigation-display-none {
  display: none;
}

/* Remove outline from all input fields */
input:focus {
  outline: none;
}

/* LOADER */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loader-header {
  color: white;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 10px;
}

#light.loader-header {
  color: black;
}

#purple.loader-header {
  color: black;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #f4f4f4 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #f4f4f4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-animation: s3 1s infinite linear;
          animation: s3 1s infinite linear;
}

@-webkit-keyframes s3 {
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes s3 {
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

#light.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #7b72ff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-animation: s3 1s infinite linear;
          animation: s3 1s infinite linear;
}

#purple.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #7b72ff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-animation: s3 1s infinite linear;
          animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

/*
  1. Use a more-intuitive box-sizing model.
*/
/* *,
*::before,
*::after {
  box-sizing: border-box;
} */
/*
  2. Remove default margin
*/
* {
    margin: 0;
  }
  /*
    3. Allow percentage-based heights in the application
  */
  html,
  body {
    height: 100%;
  }
  /*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
  /* body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  } */
  /*
    6. Improve media defaults
  */
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  /*
    7. Remove built-in form typography styles
  */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  /*
    8. Avoid text overflows
  */
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  /*
    9. Create a root stacking context
  */
  #root,
  #__next {
    isolation: isolate;
  }

