.explorer-container {
	display: flex;
	flex-direction: column;
	width: calc(100% - 72px);
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.servers-icon {
	height: 50px;
	width: 50px;
	border-radius: 25%;
	border: 5px solid #292b2f;
	object-fit: cover;
}

#light.servers-icon {
	border: 5px solid white;
}

.explorer-img {
	height: auto;
	width: 100%;
	max-height: 400px;
	object-fit: cover;
	border-radius: 9px;
}

.explorer-banner-container {
	margin: 15px 10px;
	/* width: 100%; */
}

.explorer-banner-header {
	font-size: 18px;
	font-weight: bold;
	margin: 15px 10px;
}

#light.explorer-banner-header {
	color: black;
}

#purple.explorer-banner-header {
	color: #5F50A9;
}

.exp-link {
	/* margin: auto; */
	cursor: pointer;
}

.exp-link:hover {
	color: rgb(255, 121, 121);
}

.exp-bk {
	width: 100%;
	/* height: 100%; */
	background-color: rgba(0, 0, 0, 0.866);
	color: white;
	display: flex;
	flex-direction: row;
}

#light.exp-bk {
	background-color: white;
}

#purple.exp-bk {
	background-color: #d6c5ff;
}

.explorer-banner-server-container {
	margin: 15px 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	word-wrap: break-word;
}

.explorer-server-container {
	position: relative;
	/* border: 1px solid white; */
	background-color: #292b2f;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 350px;
	height: 280px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: 0.5s;
	box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.3);
}

#light.explorer-server-container {
	background-color: rgb(243, 243, 243);
	border: 1px solid #e9e9eb;
}

#purple.explorer-server-container {
	background-color: #bfb9dd;
	border: 1px solid #e9e9eb;
}

.explorer-server-container:hover {
	background-color: #383b41;
	box-shadow: 10px 10px 15px -15px rgba(0, 0, 0, 0.75);
}

#light.explorer-server-container:hover {
	background-color: rgb(255, 255, 255);
}

#purple.explorer-server-container:hover {
	background-color: #dfdcee;
}

.explorer-server-description {
	display: flex;
	text-align: center;
}

.explorer-server-top {
	display: flex;
	/* align-items: center; */
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	justify-content: center;
	/* background: linear-gradient(
    140deg,
    rgba(145, 173, 159, 1) 0%,
    rgba(60, 70, 78, 1) 100%
  ) */
	/* background: linear-gradient(
    to right,
    rgba(236, 206, 206, 0.8),
    rgba(182, 170, 214, 0.8),
    rgba(145, 140, 170, 0.8)
  ); */
	background: linear-gradient(to right, #f6d365, #fda085);
	margin-bottom: 30px;
	height: 100px;
	width: 100%;
}

#light.explorer-server-top {
	background: linear-gradient(
		140deg,
		rgb(199, 236, 218) 0%,
		rgb(143, 167, 187) 100%
	);
}

#purple.explorer-server-top {
	background: linear-gradient(
		140deg,
		rgb(199, 236, 218) 0%,
		rgb(143, 167, 187) 100%
	);
}

.explorer-server-img {
	position: absolute;
	bottom: 155px;
}

.explorer-server-name {
	color: white;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	flex-direction: row;
}

#light.explorer-server-name {
	color: #060607;
}

#purple.explorer-server-name {
	color: #393065;
}

.check {
	color: green;
	margin-right: 5px;
}

#purple.check {
	color: lightgreen;
}

.explorer-server-description {
	color: #b6b6b6;
	font-size: 12px;
	margin-top: 7px;
	height: 10%;
}

#light.explorer-server-description {
	color: #97979c;
}

#purple.explorer-server-description {
	color: #5F50A9;
}

.join-server {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: bold;
	margin-top: 15px;
	color: rgb(55, 137, 231);
	transition: 0.5s;
}

#purple.join-server {
	color: #393065;
}

.join-server:hover {
	color: rgb(109, 214, 255);
}

#purple.join-server:hover {
	color: #5F50A9;
}

.already-joined {
	text-transform: uppercase;
	font-size: 20px;
	margin-top: 10px;
}

#light.already-joined {
	color: black;
}

#purple.already-joined {
	color: fff;
}

.pointer {
	cursor: pointer;
}
