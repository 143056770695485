.OpenModalButton-button {
  background: none;
  border: 0;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.Create-Channel {
  background: none;
  border: 0;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

#dark.OpenModalButton-button > div > .align-right {
  color: #96989d;
}

#light.fa-solid.fa-plus.align-right {
  color: #5e6772;
}

#purple.fa-solid.fa-plus.align-right {
  color: #393065;
}

.Edit,
.Delete {
  display: flex;
  gap: 8px;
  padding: 0px 5px;
  align-items: center;
  margin-left: 0;
}

.Edit-Channel {
  font-size: 12px;
  color: #96989d;
  display: flex;
  align-items: center;
  margin-left: 0;
}

#purple.Edit-Channel {
  color: #dfdcee;
}

.DeleteChannel {
  color: #ffffff;
  background: none;
  border: 0;
  font-size: 13px;
  margin-left: 8px;
}
